import Image from 'next/image';

import { staticMediaStoreBaseURL } from '@/config/common';

const HeadingWithBackAndDownArrow = ({ handleBack, handleModal, heading }) => (
  <div className='flex'>
    <Image
      src={`${staticMediaStoreBaseURL}/icons/left-arrow.svg`}
      alt='Back'
      height={12}
      width={13}
      className='cursor-pointer'
      onClick={() => handleBack()}
    />

    <div
      onClick={handleModal}
      className='flex ms-2 xxxs:ms-4 items-center'
    >
      <div className='w-full text-nero overflow-hidden font-medium text-xs basic:text-sm me-2 truncate'>
        {heading}
      </div>

      <Image
        src={`${staticMediaStoreBaseURL}/icons/down-arrow.svg`}
        alt='Down Arrow'
        className='cursor-pointer'
        height={6}
        width={10}
      />
    </div>
  </div>
);

export default HeadingWithBackAndDownArrow;
