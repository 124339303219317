import Image from 'next/image';
import Link from 'next/link';

import { staticMediaStoreBaseURL } from '@/config/common';

const ArrowIcon = ({ activeSelected, routePath }) => (
  <div className='absolute right-5 flex items-center'>
    <Image
      src={`${staticMediaStoreBaseURL}/icons/${
        !activeSelected || activeSelected === routePath
          ? 'right-arrow-orange.svg'
          : 'right-angle.svg'
      }`}
      height={12}
      width={6}
      objectFit='initial'
      alt='Arrow'
    />
  </div>
);

const UserProfileTabItem = ({
  activeSelected,
  toNavigate,
  icon,
  iconBackground,
  tabName,
  routePath
}) => (
  <Link href={toNavigate}>
    <div
      className={`${
        routePath === 'my-orders' ? 'md:hidden' : ''
      } relative cursor-pointer h-15 flex items-center mb-5 shadow-profile hover:shadow-listmd focus:shadow-listmd rounded-2.5 p-3.5 ${
        activeSelected === routePath ? 'md:text-coral' : 'text-nero'
      }`}
    >
      <div className={iconBackground}>
        <Image
          src={`${staticMediaStoreBaseURL}/icons/${icon}`}
          height={20}
          width={18}
          objectFit='initial'
          alt={tabName}
        />
      </div>
      <span className='ms-6 text-base font-medium text-nero'>{tabName}</span>
      <ArrowIcon {...{ activeSelected, routePath }} />
    </div>
  </Link>
);

export default UserProfileTabItem;
