import PropTypes from 'prop-types';

import { RichTextEditor } from '@/components/atomic/nuclei';

const PriceNotes = ({ cart: { externalNotes } }) =>
  externalNotes && (
    <div className='price-notes'>
      <div className='font-medium leading-4 text-nero text-sm md:text-base'>
        Note:
      </div>
      <div className='font-above-normal text-sm text-dim-gray'>
        <RichTextEditor {...{ readOnly: true, values: externalNotes }} />
      </div>
    </div>
  );

export default PriceNotes;

PriceNotes.propTypes = {
  cart: PropTypes.object
};
