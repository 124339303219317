import { ChargesLabel, Price, PriceLabel } from '@/components/atomic/nuclei';

const DeliveryChargeHostCart = ({ deliveryChargeAmount }) =>
  deliveryChargeAmount > 0 && (
    <div className='flex justify-between leading-4 pt-3'>
      <ChargesLabel>
        Delivery Charges
        <PriceLabel {...{ isBracket: true }} />
      </ChargesLabel>
      <Price value={deliveryChargeAmount} />
    </div>
  );

export default DeliveryChargeHostCart;
