import phoneCountryCodes from '@/config/phoneCountryCodes';

const PhoneCodes = (props) => {
  const renderOptions = () => {
    const options = [];
    const map = {};
    const { showFirst, excludeOtherOptions, codeFirst, codeOnly } = props;
    if (showFirst && Array.isArray(showFirst)) {
      showFirst.forEach((country) => {
        map[country] = true;
        const countryCode = phoneCountryCodes[country];
        if (countryCode) {
          const label = codeFirst ? countryCode.code : countryCode.name;
          const value = codeFirst ? countryCode.name : countryCode.code;
          const dropLable = `${label} (${value})`;
          options.push(
            <option
              key={country}
              value={countryCode.code}
              className={props.optionClassName}
            >
              {codeOnly ? countryCode.code : dropLable}
            </option>
          );
        }
      });
    } else {
      options.push(
        <option
          key='--'
          value=''
          className={props.optionClassName}
        >
          {props.defaultValue || ''}
        </option>
      );
    }
    if (excludeOtherOptions) {
      return options;
    }
    Object.keys(phoneCountryCodes).forEach((country) => {
      const countryCode = phoneCountryCodes[country];
      if (!map[country]) {
        const label = codeFirst ? countryCode.code : countryCode.name;
        const value = codeFirst ? countryCode.name : countryCode.code;
        const dropLable = `${label} (${value})`;
        options.push(
          <option
            key={country}
            value={countryCode.code}
            className={props.optionClassName}
          >
            {codeOnly ? countryCode.code : dropLable}
          </option>
        );
      }
    });
    return options;
  };
  return (
    <select
      disabled={props.isDisable === true ?? false}
      name={props.name}
      id={props.id}
      className={props.className}
      onChange={(e) => props.onSelect(e.target.value)}
      defaultValue={props.defaultValue}
    >
      {renderOptions()}
    </select>
  );
};

export default PhoneCodes;
