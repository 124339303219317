const HebaLoader = () => (
  <div class='heba-loading-container'>
    <div class='heba-loading-illustration'>
      <div class='heba-loading-animation'></div>
    </div>
    <div class='heba-loading-text'>
      <h2>Heba is preparing your perfect event experience🪄</h2>
      <p>Please hold on for a moment while we setup everything for you ✨.</p>
    </div>
  </div>
);

export default HebaLoader;
