import { InputLabel, Text } from 'components/atomic/nuclei';

const AddressButton = ({ eventAddress, setShowAddAddress }) => (
  <div>
    <button
      className='w-full text-coral text-sm md:text-base font-medium rounded-md border border-orange py-1 leading-4 focus:outline-none'
      onClick={() => setShowAddAddress(true)}
    >
      Add Address
    </button>
    {eventAddress && (
      <span
        className={
          'mt-1 w-full text-red-500 text-xs font-medium capitalize invalid-feedback'
        }
      >
        {eventAddress.message}
      </span>
    )}
  </div>
);

const FormatedAddress = ({
  buildingName,
  disabled,
  formattedAddress,
  houseNumber,
  landmark,
  setShowAddAddress,
  userAddress
}) => (
  <div className='mt-4'>
    {userAddress && (
      <div
        className={`max-h-23 w-full overflow-y-auto whitespace-nowrap md:whitespace-normal
md:overflow-y-hidden md:h-16.5 appearance-none cursor-pointer placeholder-silver border-b
border-platinum pb-2 md:overflow-x-scroll no-scrollbar bg-white font-medium text-sm leading-5
outline-none
`}
      >
        {
          // TODO: Could be written more succinctly.
          [houseNumber, buildingName, landmark, formattedAddress]
            .filter((elem) => elem && elem.trim() !== '')
            .join(', ')
        }
      </div>
    )}
    <InputLabel
      className={
        'block md:whitespace-normal text-sm font-Montserrat text-cinnabar font-medium text-right mt-2 me-1 cursor-pointer'
      }
    >
      {!disabled && (
        <span onClick={() => setShowAddAddress(true)}> Change Address </span>
      )}
    </InputLabel>
  </div>
);

const FormattedAddress = ({
  disabled = false,
  errors,
  setShowAddAddress,
  setValue,
  userAddress
}) => {
  const {
    buildingName,
    eventAddress,
    formattedAddress,
    houseNumber,
    landmark
  } = userAddress;
  if (formattedAddress !== '') {
    setValue('location', formattedAddress);
  }
  return (
    <div className='flex flex-col mt-5 w-full'>
      <Text
        {...{
          content: 'Event Location',
          HtmlTag: 'label',
          className: 'text-sm leading-17px font-medium text-nero mb-2'
        }}
      />
      {formattedAddress || disabled ? (
        <FormatedAddress
          {...{
            buildingName,
            disabled,
            formattedAddress,
            houseNumber,
            landmark,
            setShowAddAddress,
            userAddress
          }}
        />
      ) : (
        <AddressButton
          {...{
            dbName: 'location',
            eventAddress,
            setShowAddAddress
          }}
        />
      )}
      {errors?.location && (
        <Text
          {...{
            className:
              'mt-1 w-full text-red-500 text-xs font-medium capitalize invalid-feedback',
            content: errors.location.message,
            HtmlTag: 'span'
          }}
        />
      )}
    </div>
  );
};

export default FormattedAddress;
