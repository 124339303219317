import { FormInput, SelectFormInput, Text } from 'components/atomic/nuclei';

const EventTypeRow = ({
  clearErrors,
  control,
  dbkeyForFestival,
  dbName,
  disabled = false,
  errors,
  eventTypeOptions,
  getValues,
  placeholderLabel = '',
  register,
  setValue
}) => (
  <div className='grid grid-cols-2 gap-5 mb-3'>
    <div>
      <FormInput
        {...{
          dbKeyName: dbkeyForFestival || 'eventTitle',
          disabled,
          errors,
          height: 'h-10',
          label: 'Event Title',
          placeholder: 'Enter event title',
          register,
          type: 'text'
        }}
      />
    </div>
    <div className='flex flex-col border-platinum w-full pb-2 border-b mb-3'>
      <Text
        {...{
          className: 'text-sm leading-17px font-medium text-nero mb-3',
          content: 'Event type',
          HtmlTag: 'label'
        }}
      />
      <SelectFormInput
        {...{
          clearErrors,
          control,
          dbName: dbName || 'checkoutEventId',
          disabled,
          errors,
          getValues,
          name: 'Checkout Event Id',
          placeholderLabel,
          register,
          setValue,
          values: eventTypeOptions
        }}
      />
    </div>
  </div>
);

export default EventTypeRow;
