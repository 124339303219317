import Image from 'next/image';
import { useRouter } from 'next/router';

import { useUIContext } from '@/components/context/uiContext/ManagedUiContext';
import { DEVICE_SURFACE, staticMediaStoreBaseURL } from '@/config/common';
import phoneConfig from '@/config/phone';
import { getFormattedNumber, PNF } from '@/helpers/formatPhoneNumber';
import { captureGTMEventCTAClick, GTM_EVENTS_CTA_NAME } from '@/lib/gtm';

const { phoneNumbers } = phoneConfig;

const uaeLandlineNumber = getFormattedNumber(
  phoneNumbers.haflaLandlineMaqsam,
  'UAE',
  PNF.E164
);

const CallIconWeb = ({ onScrollNotInHomeScreen }) => (
  <Image
    alt='Call'
    className='cursor-pointer'
    height={33}
    layout='fixed'
    src={`${staticMediaStoreBaseURL}${
      onScrollNotInHomeScreen
        ? '/icons/call-round-mobile.svg'
        : '/icons/call-round-white.svg'
    }`}
    width={33}
  />
);

const CallIconMobile = () => (
  <Image
    alt='Call'
    className='cursor-pointer'
    height={24}
    layout='fixed'
    src={`${staticMediaStoreBaseURL}/icons/call-round-mobile.svg`}
    width={24}
  />
);

const CallIconView = {
  [DEVICE_SURFACE.MOBILE]: CallIconMobile,
  [DEVICE_SURFACE.WEB]: CallIconWeb
};

const CallIcon = ({ onScrollNotInHomeScreen }) => {
  const uiContext = useUIContext();
  const { surface } = uiContext;
  const CallIconForDevice = CallIconView[surface];
  const router = useRouter();

  return (
    <div className='flex'>
      <a
        className='flex'
        href={`tel:${uaeLandlineNumber}`}
        id='call-icon'
        onClick={() =>
          captureGTMEventCTAClick({
            ctaName: GTM_EVENTS_CTA_NAME.PHONE_CALL,
            path: router.pathname
          })
        }
      >
        <CallIconForDevice {...{ onScrollNotInHomeScreen }} />
      </a>
    </div>
  );
};

export default CallIcon;
