import Image from 'next/image';
import Link from 'next/link';

import { Text } from '@/components/atomic/nuclei';
import { staticMediaStoreBaseURL } from '@/config/common';
import {
  captureGTMEventCTAClick,
  GTM_EVENTS_CTA_NAME,
  PAGE_NAME
} from '@/lib/gtm';
import { getPageURL, ROUTE_CONFIG } from '@/services/hostPortal.service';

const EventPlanningWrapper = ({ children, eventId }) => (
  <div className='bg-snowwhite py-10 md:py-20 relative'>
    <div className='fixed z-120 bottom-5 right-6 cursor-pointer'>
      <Link
        href={getPageURL({
          pageName: ROUTE_CONFIG.EVENTS_LEAD_GENERATION_FORM.label,
          pathParams: {
            id: eventId
          }
        })}
      >
        <a
          className='relative'
          onClick={() => {
            captureGTMEventCTAClick({
              ctaName: GTM_EVENTS_CTA_NAME.CONSULT_EVENT_EXPERT,
              pageName: PAGE_NAME.EVENT_LEAD_GEN.label
            });
          }}
        >
          <div className='max-w-26 md:max-w-47.5 h-22.5 md:h-full'>
            <Image
              alt='Schedule Free Consultation'
              height={182}
              src={`${staticMediaStoreBaseURL}/icons/consultation-icon.svg`}
              width={190}
            />
          </div>
          <Text
            {...{
              className:
                'text-white text-xxs md:text-sm font-brandon uppercase absolute left-30/100 md:left-1/3 top-30/100 md:top-1/3',
              content: 'Schedule Free Consultation',
              HtmlTag: 'span'
            }}
          />
        </a>
      </Link>
    </div>
    {children}
  </div>
);

export default EventPlanningWrapper;
