import Image from 'next/image';

import { Text } from '@/components/atomic/nuclei';
import { staticMediaStoreBaseURL } from '@/config/common';

const RenderQuoteSubmittedNotification = ({ alertMessage, handleClose }) => (
  <div className='md:h-13 h-16 bg-black border border-black px-8 py-3 rounded-md cursor-pointer fixed md:bottom-10 bottom-24 w-full md:w-2/4'>
    <Text
      {...{
        className: 'text-white text-sm inline-block',
        content: alertMessage,
        HtmlTag: 'span'
      }}
    />
    <a
      className='close-notification hidden'
      onClick={handleClose}
    >
      <Image
        alt='close'
        height={12}
        src={`${staticMediaStoreBaseURL}/products/close.svg`}
        width={12}
      />
    </a>
  </div>
);

export default RenderQuoteSubmittedNotification;
