import Image from 'next/image';
import { CarouselProvider, Slide, Slider } from 'pure-react-carousel';

import { staticMediaStoreBaseURL } from '@/config/common';
import { useIsMobile } from '@/helpers/screenResolutionHelper';

const Sponsors = ({ logos }) => {
  const [isMobile] = useIsMobile();

  return (
    <CarouselProvider
      className='mx-auto py-3 md:py-7 px-5 md:px-0'
      infinite={true}
      interval={1000}
      isPlaying={true}
      lockOnWindowScroll={true}
      naturalSlideHeight={isMobile ? 55 : 86}
      naturalSlideWidth={isMobile ? 70 : 120}
      step={1}
      totalSlides={15}
      touchEnabled
      visibleSlides={isMobile ? 4 : 5}
    >
      <Slider
        className='md:mx-16 h-13 xxs:h-15 md:h-22'
        classNameAnimation='scroll-animation'
      >
        {logos.map((logoUrl, index) => (
          <div key={index}>
            <Slide
              className='cursor-pointer slide-inner-parent'
              index={index}
              innerClassName='slide-inner'
            >
              <Image
                alt='Sponsors'
                className='max-w-34 w-full mx-auto cursor-pointer'
                height={64}
                layout='fixed'
                src={`${staticMediaStoreBaseURL}${logoUrl}`}
                width={136}
              />
            </Slide>
          </div>
        ))}
      </Slider>
    </CarouselProvider>
  );
};

export default Sponsors;
