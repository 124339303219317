import Image from 'next/image';
import Link from 'next/link';

import { mediaStoreBaseURL, ORDER_STATUS } from '@/config/common';
import { getFormattedDate } from '@/lib/time';
import { getPageURL, ROUTE_CONFIG } from '@/services/hostPortal.service';

const OrderList = ({ order, showSecond }) => (
  <Link
    href={getPageURL({
      pageName: ROUTE_CONFIG.ORDER_DETAILS.label,
      pathParams: {
        orderId: order.id
      }
    })}
  >
    <div className='px-5 md:p-0 md:mb-6'>
      <div className='w-full mx-auto shadow-orderbox h-24 md:h-32 rounded-2.5 my-3 md:my-0 p-4 border border-platinum relative cursor-pointer'>
        {!showSecond && (
          <div className='absolute z-10 right-3 top-3 bg-lightpink rounded-xl h-4 flex items-center'>
            <span className='font-lato text-xs text-coral px-3 py-0.5'>
              {ORDER_STATUS[order.status].label}
            </span>
          </div>
        )}
        <div className='flex h-full my-auto'>
          <div className='w-16 h-16 md:w-25 md:h-25 relative cursor-pointer my-auto flex-grow-0 rounded-xl overflow-hidden'>
            <Image
              alt='eventType'
              layout='fill'
              objectFit='cover'
              src={`${mediaStoreBaseURL}/${order?.userEventDetail?.checkoutEvent?.iconUrl}`}
            />
          </div>
          <div className='flex flex-col flex-grow-0 justify-center pl-3 md:pl-6 text-nero'>
            <span className='text-base capitalize md:text-lg leading-3 md:leading-4 font-medium'>
              {order?.userEventDetail?.eventTitle ||
                order?.userEventDetail?.checkoutEvent?.event?.name}
            </span>
            <span className='text-xs md:text-sm leading-4 pt-1.5 font-medium'>
              <b>
                on
                {getFormattedDate({
                  date: order?.userEventDetail?.eventDate,
                  format: 'MMM Do'
                })}
              </b>
            </span>
          </div>
        </div>
      </div>
    </div>
  </Link>
);

export default OrderList;
