import { CloseIcon, Text } from '@/components/atomic/nuclei';

const RenderCartNotification = ({ alertMessage, handleClose }) => (
  <div className='absolute md:bottom-0 flex justify-center left-0 md:left-74 md:px-6'>
    <div className='absolute'>
      <div className='cursor-pointer z-99 fixed md:bottom-10 bottom-22.5 w-full md:w-2/4'>
        <div className='h-13 rounded-md bg-black px-2.5 basic:px-8 py-3 mx-5 md:mx-0'>
          <Text
            {...{
              className: 'text-white text-sm inline-block',
              content: alertMessage,
              HtmlTag: 'span'
            }}
          />
          <CloseIcon
            {...{
              className: 'close-notification mt-2',
              iconClassName: '',
              onClick: handleClose
            }}
          />
        </div>
      </div>
    </div>
  </div>
);

export default RenderCartNotification;
