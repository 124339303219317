import Image from 'next/image';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import { Text } from '@/components/atomic/nuclei';
import { staticMediaStoreBaseURL } from '@/config/common';
import { getPageURL, ROUTE_CONFIG } from '@/services/hostPortal.service';
import { getSuggestions } from '@/services/search.service';

const SearchModal = ({ searchedValue = '', setShowSearchModal }) => {
  const Router = useRouter();
  const [searchValue, setSearchValue] = useState(searchedValue);
  const [suggestions, setSuggestions] = useState([]);
  const handleSearch = async (e) => {
    setSearchValue(e.target.value);
    const response = await getSuggestions(e.target.value);
    setSuggestions([]);
    if (response.status && response.entity.count) {
      setSuggestions(response.entity.hits);
    }
  };
  useEffect(() => {
    setSearchValue(searchedValue);
  }, [searchedValue]);

  const selectingSuggestion = (suggestion) => {
    setSearchValue(suggestion);
    setSuggestions([]);
    setShowSearchModal((prevState) => !prevState);
    Router.push(
      getPageURL({
        pageName: ROUTE_CONFIG.PRODUCT_SEARCH_RESULT.label,
        pathParams: {
          searchQuery: suggestion
        },
        queryParams: {
          searchedQuery: suggestion
        }
      })
    );
  };

  return (
    <div className='fixed inset-0 z-50 bg-white md:hidden'>
      <div className='h-12 py-3 z-50 w-full max-w-full mx-auto shadow-lg px-5 flex items-center'>
        <Image
          alt='backIcon'
          className='inline-block hafla-header-back cursor-pointer'
          height={18}
          layout='fixed'
          onClick={() => setShowSearchModal((prevState) => !prevState)}
          src={`${staticMediaStoreBaseURL}/icons/back-angle-black.svg`}
          width={10}
        />
        <input
          autoComplete='off'
          autoFocus
          className='ms-4 focus:bg-transparent focus-within:bg-transparent outline-none w-full max-w-msearch md:max-w-full text-nero font-Montserrat'
          id='search'
          onChange={handleSearch}
          onKeyDown={(e) => {
            if (e.which === 13 && e.target.value.length) {
              selectingSuggestion(searchValue);
            }
          }}
          placeholder='Search furniture, bouncy castles, birthday packages, artists &amp; more'
          type='text'
          value={searchValue}
        />
      </div>
      {Boolean(suggestions.length) && (
        <div className='absolute bg-white z-20 w-full flex flex-col transform translate-x-8'>
          {suggestions.map(({ _source: { name: sourceName } }, index) => (
            <span
              className='p-2 hover:bg-platinum-300 cursor-pointer'
              key={index}
              onClick={() => selectingSuggestion(sourceName)}
            >
              {sourceName}
            </span>
          ))}
          <div
            className='p-2 hover:bg-platinum-300 cursor-pointer text-coral'
            onClick={() => selectingSuggestion(searchValue)}
          >
            <Text
              {...{
                content: `See all results for “${searchValue}”`,
                HtmlTag: 'span'
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default SearchModal;
