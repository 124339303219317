import 'material-icons/iconfont/material-icons.css';
import { appWithTranslation } from 'next-i18next';
import nextI18NextConfig from 'next-i18next.config';
import { DefaultSeo } from 'next-seo';
import Head from 'next/head';

import 'pure-react-carousel/dist/react-carousel.es.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'styles/botpress.css';
import 'styles/carousel.css';
import 'styles/editor.css';
import 'styles/globals.css';
import 'styles/searchkittheme.css';
import 'styles/styles.scss';
import 'styles/timePicker.css';

import { RecordPageViewEvent, WithAuth } from 'components/atomic/atoms';
import {
  ManagedUIContext,
  useUIContext
} from 'components/context/uiContext/ManagedUiContext';
import SEO from 'next-seo.config';

const DOMContextProvider = ({ children }) => {
  const { surface, role } = useUIContext();
  return (
    <div className={`surface-${surface} role-${role}`}>
      <RecordPageViewEvent {...{ children }} />
    </div>
  );
};

const Base = ({ Component, pageProps, router }) => (
  <ManagedUIContext>
    <WithAuth router={router}>
      <Head>
        <meta
          name='viewport'
          content='width=device-width, minimum-scale=1.0, maximum-scale=1.0, user-scalable=0'
        />
      </Head>
      <DOMContextProvider>
        <DefaultSeo {...SEO} />
        <Component {...pageProps} />
      </DOMContextProvider>
    </WithAuth>
  </ManagedUIContext>
);

const MyApp = (props) => <Base {...props} />;

export default appWithTranslation(MyApp, nextI18NextConfig);
