import Image from 'next/image';
import { useRouter } from 'next/router';

import { Text } from '@/components/atomic/nuclei';
import { staticMediaStoreBaseURL } from '@/config/common';
import { captureGTMEventNavBarClick } from '@/lib/gtm';
import { getPageURL, ROUTE_CONFIG } from '@/services/hostPortal.service';

const eventsName = ['Event Equipment', 'People & Services'];

const EventList = ({ categoryName, handleNavOnHover, slug }) => (
  <div className='flex'>
    <button className='w-full min-w-max relative focus:outline-none me-2'>
      {categoryName}
    </button>
    {eventsName.includes(categoryName) && (
      <div>
        <Image
          src={`${staticMediaStoreBaseURL}/icons/down-arrow.svg`}
          alt='arrow-down-icon'
          height={10}
          width={10}
          className='cursor-pointer'
          onClick={() => handleNavOnHover(slug)}
        />
      </div>
    )}
  </div>
);

const HeaderEventList = ({
  categories,
  showHeaderEventList,
  handleNavOnHover
}) => {
  const router = useRouter();

  if (!router.isReady) {
    return <div>Loading...</div>;
  }

  return (
    <div className='sticky top-20 bg-ghost-white block pl-5 md:px-20'>
      <div className='flex overflow-x-scroll no-scrollbar md:overflow-hidden font-medium font-Montserrat text-10px md:text-base py-3 md:py-3.5'>
        <ul className='flex space-x-2 md:space-x-6 eventlist'>
          {categories?.map(
            ({ id, isHidden, name: categoryName, slug }) =>
              !isHidden && (
                <a
                  key={id}
                  href={getPageURL({
                    pageName: ROUTE_CONFIG.L0_CATEGORY.label,
                    pathParams: {
                      parentSlug: slug
                    }
                  })}
                  onClick={() => {
                    captureGTMEventNavBarClick({
                      category: { name: categoryName },
                      path: router.pathname
                    });
                  }}
                >
                  <div
                    className='cursor-pointer w-max'
                    onMouseEnter={() => handleNavOnHover(slug)}
                  >
                    {showHeaderEventList ? (
                      <EventList
                        {...{ categoryName, handleNavOnHover, slug }}
                      />
                    ) : (
                      <Text
                        {...{
                          className: 'w-full min-w-max relative',
                          content: categoryName,
                          HtmlTag: 'li'
                        }}
                      />
                    )}
                  </div>
                </a>
              )
          )}
        </ul>
      </div>
    </div>
  );
};

export default HeaderEventList;
