import Image from 'next/image';
import Link from 'next/link';

import { Text } from '@/components/atomic/nuclei';
import { staticMediaStoreBaseURL } from '@/config/common';

const Icon = ({ iconSrc }) => (
  <div className='self-center flex'>
    <Image
      src={`${staticMediaStoreBaseURL}/icons/${iconSrc}`}
      width={16}
      height={16}
      alt='ToasterIcon'
      layout='fixed'
    />
  </div>
);

const ActionButton = ({ actionLink, actionLabel }) => (
  <Link href={actionLink}>
    <Text
      content={actionLabel}
      className='cursor-pointer text-brand'
    />
  </Link>
);

const Toaster = ({
  message,
  action,
  iconSrc,
  showActionButton,
  showIcon,
  setMessage
}) => {
  setTimeout(
    () => setMessage({ show: false, status: false, message: '' }),
    5000
  );
  return (
    <div
      className={`bg-black text-white text-sm font-medium flex gap-3 justify-center py-3
      rounded-lg fixed px-6 opacity-80 bottom-10 left-1/2 w-4/5 md:w-auto toaster z-50`}
    >
      {showIcon && <Icon {...{ iconSrc }} />}
      <Text
        content={message}
        className='self-center'
      />
      {showActionButton && <ActionButton {...{ ...action }} />}
    </div>
  );
};

export default Toaster;
