import { ChargesLabel, Price, PriceLabel } from '@/components/atomic/nuclei';

const LumpSumDiscountHostCart = ({ lumpSumDiscount = 0 }) =>
  Boolean(lumpSumDiscount) && (
    <div className='flex justify-between leading-4 pt-3'>
      <ChargesLabel>
        Bulk Discount
        <PriceLabel {...{ isBracket: true }} />
      </ChargesLabel>
      <Price {...{ value: lumpSumDiscount, isDiscount: true }} />
    </div>
  );

export default LumpSumDiscountHostCart;
