import { ChargesLabel, Price } from '@/components/atomic/nuclei';

const PromoHostCart = ({ promoCodeDiscountAmount, promoCode }) =>
  promoCode?.id && (
    <div className='flex justify-between leading-4 pt-3'>
      <ChargesLabel>
        Promo (
        <ChargesLabel {...{ isDiscount: true }}>{promoCode.code}</ChargesLabel>)
      </ChargesLabel>
      <Price {...{ value: promoCodeDiscountAmount, isDiscount: true }} />
    </div>
  );

export default PromoHostCart;
