import { ChargesLabel, Price } from '@/components/atomic/nuclei';

const VatAmountHostCart = ({ vatAmount }) => (
  <div className='flex justify-between leading-4 pt-3'>
    <ChargesLabel>VAT (5%)</ChargesLabel>
    <Price {...{ value: vatAmount }} />
  </div>
);

export default VatAmountHostCart;
