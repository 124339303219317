import { ChargesLabel, Price, PriceLabel } from '@/components/atomic/nuclei';

const TotalItemsPriceHostCart = ({
  listedPriceAmount,
  listedDiscountAmount,
  cartItems
}) => {
  const ItemCount = cartItems.length;
  return (
    listedDiscountAmount > 0 && (
      <div className='flex justify-between leading-4 pt-4 md:pt-4'>
        <ChargesLabel>
          Sub-total ({ItemCount} {ItemCount > 1 ? 'items' : 'item'})
          <PriceLabel {...{ isBracket: true }} />
        </ChargesLabel>
        <Price {...{ value: listedPriceAmount }} />
      </div>
    )
  );
};

export default TotalItemsPriceHostCart;
