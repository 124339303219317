import { Text } from '@/components/atomic/nuclei';

const MyOrderTabHeader = ({
  firstValue,
  secondValue,
  setShowSecond,
  showSecond
}) => (
  <div className='w-full flex items-center h-12 mb-5 md:mb-10 md:mt-6 md:space-x-12'>
    <div
      onClick={() => setShowSecond((prevState) => !prevState)}
      className={`flex-grow md:flex-grow-0 justify-center text-center border-b-2 p-3 md:p-0 cursor-pointer text-sm md:text-xl md:border-none ${
        !showSecond ? 'border-orange text-coral items-center' : ''
      }`}
    >
      <Text
        {...{
          className: 'md:block md:pb-2',
          content: firstValue,
          HtmlTag: 'span'
        }}
      />
      <Text
        {...{
          className: `${
            !showSecond
              ? 'md:block md:w-15 md:min-w-15 md:mx-auto md:border-orange md:border-b-4 md:h-1'
              : 'md:border-b-4 md:border-transparent md:h-1 md:block'
          }`,
          HtmlTag: 'span'
        }}
      />
    </div>
    <div
      onClick={() => setShowSecond((prevState) => !prevState)}
      className={`flex-grow md:flex-grow-0 justify-center text-center border-b-2 p-3 md:p-0 cursor-pointer text-sm md:text-xl md:border-none ${
        showSecond ? 'border-orange text-coral items-center' : ''
      }`}
    >
      <Text
        {...{
          className: 'md:block md:pb-2',
          content: secondValue,
          HtmlTag: 'span'
        }}
      />
      <Text
        {...{
          className: `${
            showSecond
              ? 'md:block md:w-15 md:min-w-15 md:mx-auto md:border-orange md:border-b-4 md:h-1'
              : 'md:border-b-4 md:border-transparent md:h-1 md:block'
          }`,
          HtmlTag: 'span'
        }}
      />
    </div>
  </div>
);

export default MyOrderTabHeader;
