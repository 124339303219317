import { Button } from '@/components/atomic/nuclei';

const AddAddressButton = ({ setShowAddAddress }) => (
  <div>
    <Button
      type='button'
      className={
        'h-12.5 py-4 rounded-md border border-cinnabar text-cinnabar text-base font-medium leading-5 w-full'
      }
      onClick={() => setShowAddAddress(true)}
    >
      Add Address
    </Button>
  </div>
);

export default AddAddressButton;
