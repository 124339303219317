import { ChargesLabel, Price, PriceLabel } from '@/components/atomic/nuclei';

const PreVatPriceHostCart = ({ preVatAmount }) => (
  <div className='flex justify-between leading-4 pt-4 md:pt-4'>
    <ChargesLabel>
      Pre-VAT total
      <PriceLabel {...{ isBracket: true }} />
    </ChargesLabel>
    <Price {...{ value: preVatAmount }} />
  </div>
);

export default PreVatPriceHostCart;
