import { useEffect } from 'react';

import { Button, Text } from '@/components/atomic/nuclei';

const LoggedIn = ({ currentStep, setCurrentStep, setStepReached }) => {
  const handleClick = () => {
    setCurrentStep(currentStep + 1);
    setStepReached(currentStep + 1);
  };

  useEffect(() => {
    setCurrentStep(2);
    setStepReached(2);
  }, []);

  return (
    <div className='max-w-55 mx-auto text-center pt-12 pb-20 transition-all duration-500 ease-in-out'>
      <Text
        {...{
          className:
            'text-base text-dim-gray leading-5 w-full text-center mb-8',
          content: 'Logged in'
        }}
      />
      <Button
        className='bg-brand-gradient text-white font-medium h-12.5 py-4 rounded-md'
        onClick={handleClick}
      >
        Continue Checkout
      </Button>
    </div>
  );
};

export default LoggedIn;
