import Image from 'next/image';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { Button, Text } from 'components/atomic/nuclei';
import { DEVICE_SURFACE, staticMediaStoreBaseURL } from 'config/common';
import { getUserDetails } from 'helpers/getUserDetails';
import inputValidationConfig from 'lib/validations/input-validations';
import { yupValidator } from 'lib/yup-validator';
import { getAuth } from 'services/identity.service';
import { updateUserDetails } from 'services/user.service';

const { nameInputError, nameRegex } =
  inputValidationConfig.hostNameValidationConfig;

const ErrorMessage = ({ message }) =>
  message && (
    <Text
      {...{
        className: 'font-light text-xs text-red-700',
        content: message
      }}
    />
  );

const ActionButton = ({ handleClick, text }) => (
  <div className='w-full flex md:flex-row-reverse md:justify-start justify-center mt-7'>
    <Button
      {...{
        children: text,
        className:
          'bg-coral text-white text-sm md:text-base rounded-lg py-4 leading-4 font-medium focus:outline-none md:max-w-40 my-0 h-12',
        onClick: handleClick
      }}
    />
  </div>
);

const PersonalDetail = ({
  hidePersonalDetail,
  isLoading,
  savedName,
  setIsLoading,
  setSavedName
}) => {
  const auth = getAuth();
  const [enableEdit, setEnableEdit] = useState(false);

  const customerDefaultValue = {
    resolver: yupValidator(
      yup.object().shape({
        name: yup
          .string()
          .transform((_, val) => val.toLowerCase())
          .trim()
          .matches(nameRegex, nameInputError)
          .required('Customer Name is mandatory'),
        email: yup
          .string()
          .transform((_, val) => val.toLowerCase())
          .email()
          .required('Email is mandatory')
      })
    )
  };

  const {
    formState: { errors },
    getValues,
    handleSubmit,
    register,
    setValue
  } = useForm(customerDefaultValue);

  const fetchAndSetUserDetails = async () => {
    const user = await getUserDetails(auth.id);
    if (user) {
      setIsLoading(false);
      if (user.name) {
        setSavedName(user.name);
        setValue('name', user.name);
      }
      user.email && setValue('email', user.email);
      user.mobile && setValue('mobile', user.mobile);
    }
  };

  useEffect(() => {
    fetchAndSetUserDetails();
  }, []);

  const onSubmit = async ({ email, name }) => {
    const response = await updateUserDetails(auth.id, { email, name });
    if (response.status) {
      fetchAndSetUserDetails();
      setEnableEdit(false);
    }
  };

  const FormInput = ({
    mainDivClass = '',
    text,
    value,
    placeholder,
    imgUrl,
    imgAlt,
    inputType,
    disabled,
    registerValue
  }) => (
    <div className={`mt-5 ${mainDivClass}`}>
      <div className='border-b border-gray-150 flex flex-col pb-1'>
        <label
          className='text-base font-above-normal text-dim-gray'
          htmlFor={value}
        >
          {text}
        </label>
        <div className='flex mt-3'>
          <Image
            className='cursor-pointer'
            src={`${staticMediaStoreBaseURL}${imgUrl}`}
            height={15}
            width={15}
            objectFit='initial'
            alt={imgAlt}
          />
          <input
            className={`w-full text-base font-normal focus:outline-none bg:white focus:bg-white hover:bg-white active:bg-white appearance-none ms-3 md:ms-3.5  ${
              enableEdit ? 'text-nero' : 'text-dim-gray'
            }`}
            id={value}
            type={inputType}
            {...register(registerValue || value)}
            placeholder={placeholder}
            required
            disabled={disabled}
          />
        </div>
      </div>
    </div>
  );

  const isInputDisabled = !enableEdit && savedName;

  return (
    <div
      className={`w-full px-5 md:py-7 xl:px-17.5 mt-4 md:mt-0 ${
        hidePersonalDetail && 'hidden md:block'
      }`}
    >
      {!isLoading && (
        <div className='w-full shadow-profile md:shadow-delivery rounded-2.5'>
          <div className='p-6 md:p-7'>
            {
              // TODO: FormInput could be mapped.
            }
            <FormInput
              text='Name'
              value='name'
              imgAlt='profile'
              imgUrl='/icons/profile-icon-gray.svg'
              placeholder='Enter your Name'
              disabled={isInputDisabled}
              inputType='text'
            />
            <ErrorMessage message={errors?.name?.message} />
            <FormInput
              text='E-mail'
              value='email'
              imgAlt='email'
              imgUrl='/icons/email-outline-gray.svg'
              placeholder='Enter your email'
              disabled={isInputDisabled}
              mainDivClass='md:mb-3 mt-5'
              inputType='email'
            />
            <ErrorMessage message={errors?.email?.message} />
            <FormInput
              text='Phone Number'
              value='phone'
              imgAlt='phone'
              imgUrl='/icons/phone-icon.svg'
              placeholder='Enter your Mobile Number'
              disabled={true}
              mainDivClass='md:mb-3 mt-5'
              inputType='text'
              registerValue={DEVICE_SURFACE.MOBILE}
            />
            {isInputDisabled && (
              <ActionButton
                text='Edit'
                handleClick={() => setEnableEdit(true)}
              />
            )}
            {(enableEdit || !savedName || !getValues('email')) && (
              <ActionButton
                text='Save'
                handleClick={handleSubmit(onSubmit)}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default PersonalDetail;
