import Image from 'next/image';

import { staticMediaStoreBaseURL } from '@/config/common';
import { useIsMobile } from '@/helpers/screenResolutionHelper';

const CartBadge = ({ cartItemCount }) =>
  cartItemCount > 0 && (
    <div
      className={`z-40 flex justify-center items-center absolute bg-brand-gradient text-white rounded-full text-center -top-3px md:-top-5px -right-3 md:-right-2 w-4 h-4 md:h-5.5 md:w-5.5 text-xxs md:text-xs leading-2 font-medium cart-bubble`}
    >
      {cartItemCount}
    </div>
  );

const CartIcon = ({ cartItemCount, onHomeScreenBanner }) => {
  const [isMobile] = useIsMobile();

  const imageSrc = `${staticMediaStoreBaseURL}/icons/${
    onHomeScreenBanner ? 'cart-white.svg' : 'cart-red.svg'
  }`;
  const cartIcon = isMobile
    ? `${staticMediaStoreBaseURL}/icons/cart-red.svg`
    : imageSrc;

  return (
    <div className='flex items-center justify-center'>
      <Image
        width={isMobile ? 25 : 33}
        height={isMobile ? 25 : 33}
        className='cursor-pointer'
        src={cartIcon}
        layout='fixed'
        alt='Cart'
      />
      <CartBadge {...{ cartItemCount }} />
    </div>
  );
};

export default CartIcon;
