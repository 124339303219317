import Image from 'next/image';
import { isMobile } from 'react-device-detect';

import { Text } from '@/components/atomic/nuclei';
import { staticMediaStoreBaseURL } from '@/config/common';

const NoSearchResult = () => (
  <div className='md:h-101 py-24 md:mt-8 md:mx-18 md:pt-20 md:pb-18 flex flex-col items-center md:border md:border-gray-200 md:rounded-2.5'>
    <Image
      alt='no search'
      className=''
      height={isMobile ? '116' : '144'}
      objectFit='initial'
      src={`${staticMediaStoreBaseURL}/common/no-search-result.svg`}
      width={isMobile ? '146' : '180'}
    />
    <Text
      {...{
        className:
          'text-17px text-nero md:text-xl w-45 md:w-73 text-center mt-8 md:mt-5 mb-3 md:mb-3',
        content: `Oops, we couldn't find any search result`
      }}
    />
    <Text
      {...{
        className:
          'text-dim-gray text-sm md:text-sm text-center w-68 md:w-full',
        content: 'Please check the spelling or try searching for something else'
      }}
    />
  </div>
);

export default NoSearchResult;
