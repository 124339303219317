import { Button } from '@/components/atomic/nuclei';

const CheckoutActionButton = ({
  handleEventInfoSubmit,
  handleReqCallBackClick,
  isReqCallBackApplicable
}) => (
  <div className='flex w-full'>
    <Button
      {...{
        className:
          'bg-brand-gradient h-12.5 py-4 rounded-md text-white text-base font-medium leading-5 w-full md:max-w-55 mt-8 ml-auto mb-7 md:mb-0',
        type: 'submit',
        onClick: isReqCallBackApplicable
          ? handleReqCallBackClick
          : handleEventInfoSubmit,
        children: isReqCallBackApplicable
          ? 'Request For Callback'
          : 'Proceed To Payment'
      }}
    />
  </div>
);

export default CheckoutActionButton;
