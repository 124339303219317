import { HOST_PORTAL_URL } from '@/services/connections.service';
import { getURLPath } from '@/services/url.service';

export const ROUTE_CONFIG = {
  ABOUT: {
    label: 'ABOUT',
    regex: /^\/about\/?(?:\?.*)?$/,
    template: '/about'
  },
  ACCEPTABLE_USE_POLICY: {
    label: 'ACCEPTABLE_USE_POLICY',
    regex: /^\/acceptable-use-policy\/?(?:\?.*)?$/,
    template: '/acceptable-use-policy'
  },
  BLOG: {
    label: 'BLOG',
    regex: /^\/blog\/?(?:\?.*)?$/,
    template: '/blog'
  },
  CATEGORIES_LIST: {
    label: 'CATEGORIES_LIST',
    regex: /^\/categories\/list\/?(?:\?.*)?$/,
    template: '/categories/list'
  },
  CHECKOUT: {
    label: 'CHECKOUT',
    regex: /^\/checkout\/?(?:\?.*)?$/,
    template: '/checkout'
  },
  CHECKOUT_CALLBACK_SUCCESS: {
    label: 'CHECKOUT_CALLBACK_SUCCESS',
    regex: /^\/checkout\/req-callback-success\/?(?:\?.*)?$/,
    template: '/checkout/req-callback-success'
  },
  L2_CATEGORY: {
    label: 'L2_CATEGORY',
    regex: /^\/categories\/[\w-]+\/[\w-]+\/[\w-]+\/?(?:\?.*)?$/,
    template: '/categories/:parentSlug/:categorySlug/:subCategorySlug'
  },
  COLLECTION_HOME: {
    label: 'COLLECTION_HOME',
    regex: /^\/collections\/[\w-]+\/?(?:\?.*)?$/,
    template: '/collections/:collectionSlug'
  },
  COLLECTION_LIST: {
    label: 'COLLECTION_LIST',
    regex: /^\/collections\/?(?:\?.*)?$/,
    template: '/collections'
  },
  CONTACT_US: {
    label: 'CONTACT_US',
    regex: /^\/contact-us\/?(?:\?.*)?$/,
    template: '/contact-us'
  },
  CORPORATE_EVENTS: {
    label: 'CORPORATE_EVENTS',
    regex: /^\/events\/corporate-events\/?(?:\?.*)?$/,
    template: '/events/corporate-events'
  },
  CORPORATE_EVENTS_GALLERY: {
    label: 'CORPORATE_EVENTS_GALLERY',
    regex: /^\/events\/corporate-events\/gallery\/?(?:\?.*)?$/,
    template: '/events/corporate-events/gallery'
  },
  EMPTY_DEFAULT_CART: {
    label: 'EMPTY_DEFAULT_CART',
    regex: /^\/empty-default-cart\/?(?:\?.*)?$/,
    template: '/empty-default-cart'
  },
  EVENTS_CART_DETAILS: {
    label: 'EVENTS_CART_DETAILS',
    regex: /^\/my-events\/[\w-]+\/event-cart-details\/?(?:\?.*)?$/,
    template: '/my-events/:userEventId/event-cart-details'
  },
  EVENTS_CELEBRATION: {
    label: 'EVENTS_CELEBRATION',
    regex: /^\/events\/celebrations\/?(?:\?.*)?$/,
    template: '/events/celebrations'
  },
  EVENTS_CELEBRATION_GALLERY: {
    label: 'EVENTS_CELEBRATION_GALLERY',
    regex: /^\/events\/celebrations\/gallery\/?(?:\?.*)?$/,
    template: '/events/celebrations/gallery'
  },
  EVENTS_GALLERY: {
    label: 'EVENTS_GALLERY',
    regex: /^\/gallery\/?(?:\?.*)?$/,
    template: '/gallery'
  },
  EVENTS_LEAD_GENERATION_FORM: {
    label: 'EVENTS_LEAD_GENERATION_FORM',
    regex: /^\/events\/lead-capture-form\/[\w-]+\/?(?:\?.*)?$/,
    template: '/events/lead-capture-form/:id'
  },
  HOME_PAGE: {
    label: 'HOME_PAGE',
    regex: /^\/\/?(?:\?.*)?$/,
    template: '/'
  },
  HOST_CART: {
    label: 'HOST_CART',
    regex: /^\/host-carts\/[\w-]+\/?(?:\?.*)?$/,
    template: '/host-carts/:cartId'
  },
  KIDS_BIRTHDAY_EVENTS: {
    label: 'KIDS_BIRTHDAY_EVENTS',
    regex: /^\/events\/kids-birthday-events\/?(?:\?.*)?$/,
    template: '/events/kids-birthday-events'
  },
  LOGIN: {
    label: 'LOGIN',
    regex: /^\/login\/?(?:\?.*)?$/,
    template: '/login'
  },
  MY_EVENTS: {
    label: 'MY_EVENTS',
    regex: /^\/my-events\/[\w-]+\/?(?:\?.*)?$/,
    template: '/my-events/:id'
  },
  MY_ORDERS: {
    label: 'MY_ORDERS',
    regex: /^\/my-orders\/?(?:\?.*)?$/,
    template: '/my-orders'
  },
  NOT_FOUND: {
    label: 'NOT_FOUND',
    regex: /^\/404\/?(?:\?.*)?$/,
    template: '/404'
  },
  ORDER_DETAILS: {
    label: 'ORDER_DETAILS',
    regex: /^\/my-orders\/[\w-]+\/order-details\/?(?:\?.*)?$/,
    template: '/my-orders/:orderId/order-details'
  },
  L0_CATEGORY: {
    label: 'L0_CATEGORY',
    regex: /^\/categories\/[\w-]+\/?(?:\?.*)?$/,
    template: '/categories/:parentSlug'
  },
  L1_CATEGORY: {
    label: 'L1_CATEGORY',
    regex: /^\/categories\/[\w-]+\/[\w-]+\/?(?:\?.*)?$/,
    template: '/categories/:parentSlug/:categorySlug'
  },
  PARTNER_BASIC_INFO: {
    label: 'PARTNER_BASIC_INFO',
    regex: /^\/partners\/basic-info\/?(?:\?.*)?$/,
    template: '/partners/basic-info'
  },
  PARTNER_BIDS: {
    label: 'PARTNER_BIDS',
    regex: /^\/partners\/[\w-]+\/bids\/?(?:\?.*)?$/,
    template: '/partners/:partnerId/bids'
  },
  PARTNER_BUSINESS_INFO: {
    label: 'PARTNER_BUSINESS_INFO',
    regex: /^\/partners\/business-info\/?(?:\?.*)?$/,
    template: '/partners/business-info'
  },
  PARTNER_BUSINESS_LOCATION_INFO: {
    label: 'PARTNER_BUSINESS_LOCATION_INFO',
    regex: /^\/partners\/business-location-info\/?(?:\?.*)?$/,
    template: '/partners/business-location-info'
  },
  PAYMENTS: {
    label: 'PAYMENTS',
    regex: /^\/payments\/?(?:\?.*)?$/,
    template: '/payments'
  },
  PAYMENTS_RESPONSE: {
    label: 'PAYMENTS_RESPONSE',
    regex: /^\/payments\/response\/?(?:\?.*)?$/,
    template: '/payments/response'
  },
  PDP: {
    label: 'PDP',
    regex: /^\/products\/[\w-]+\/?(?:\?.*)?$/,
    template: '/products/:slug'
  },
  PLAN_EVENT_WITH_HEBA: {
    label: 'PLAN_EVENT_WITH_HEBA',
    regex: /^\/ai\/plan-event-with-heba\/?(?:\?.*)?$/,
    template: '/ai/plan-event-with-heba'
  },
  PLAN_EVENT_WITH_HEBA_WHATSAPP: {
    label: 'PLAN_EVENT_WITH_HEBA_WHATSAPP',
    regex: /^\/ai\/plan-event-with-heba-whatsapp\/?(?:\?.*)?$/,
    template: '/ai/plan-event-with-heba-whatsapp'
  },
  PLAN_EVENT_WITH_HEBA_WEBCHAT: {
    label: 'PLAN_EVENT_WITH_HEBA_WEBCHAT',
    regex: /^\/ai\/plan-event-with-heba-webchat\/?(?:\?.*)?$/,
    template: '/ai/plan-event-with-heba-webchat'
  },
  PRIVACY_POLICY: {
    label: 'PRIVACY_POLICY',
    regex: /^\/privacy-policy\/?(?:\?.*)?$/,
    template: '/privacy-policy'
  },
  PRODUCT_SEARCH_RESULT: {
    label: 'PRODUCT_SEARCH_RESULT',
    regex: /^\/search-result\/[\w-]+\/?(?:\?.*)?$/,
    template: '/search-result/:searchQuery'
  },
  PRODUCT_SEARCH_RESULT_LIST: {
    label: 'PRODUCT_SEARCH_RESULT_LIST',
    regex: /^\/search-result\/?(?:\?.*)?$/,
    template: '/search-result'
  },
  QUOTE: {
    label: 'QUOTE',
    regex: /^\/quotes\/[\w-]+\/?(?:\?.*)?$/,
    template: '/quotes/:id'
  },
  TERMS_AND_CONDITION: {
    label: 'TERMS_AND_CONDITION',
    regex: /^\/terms-and-conditions\/?(?:\?.*)?$/,
    template: '/terms-and-conditions'
  },
  USER_PROFILE: {
    label: 'USER_PROFILE',
    regex: /^\/user-profile\/?(?:\?.*)?$/,
    template: '/user-profile'
  },
  USER_PROFILE_ADDRESS: {
    label: 'USER_PROFILE_ADDRESS',
    regex: /^\/user-profile\/address\/?(?:\?.*)?$/,
    template: '/user-profile/address'
  },
  USER_PROFILE_GET_HELP: {
    label: 'USER_PROFILE_GET_HELP',
    regex: /^\/user-profile\/get-help\/?(?:\?.*)?$/,
    template: '/user-profile/get-help'
  },
  USER_PROFILE_MY_EVENTS: {
    label: 'USER_PROFILE_MY_EVENTS',
    regex: /^\/user-profile\/my-events\/?(?:\?.*)?$/,
    template: '/user-profile/my-events'
  },
  USER_PROFILE_PERSONAL_DETAILS: {
    label: 'USER_PROFILE_PERSONAL_DETAILS',
    regex: /^\/user-profile\/personal-details\/?(?:\?.*)?$/,
    template: '/user-profile/personal-details'
  },
  WEDDING_EVENTS: {
    label: 'WEDDING_EVENTS',
    regex: /^\/events\/wedding-events\/?(?:\?.*)?$/,
    template: '/events/wedding-events'
  },
  WEDDING_EVENTS_GALLERY: {
    label: 'WEDDING_EVENTS_GALLERY',
    regex: /^\/events\/wedding-events\/gallery\/?(?:\?.*)?$/,
    template: '/events/wedding-events/gallery'
  }
};

export const getPageURL = ({
  excludeBaseUrl = true,
  pageName,
  pathParams = {},
  queryParams = {}
}) => {
  const pathname =
    ROUTE_CONFIG[pageName]?.template ?? ROUTE_CONFIG.NOT_FOUND.template;

  const urlSuffix = getURLPath({
    pathname,
    pathParams,
    queryParams
  });

  const urlPrefix = !excludeBaseUrl ? HOST_PORTAL_URL : '';
  return `${urlPrefix}${urlSuffix}`;
};
