import { InputLabel } from '@/components/atomic/nuclei';

const ShowFormattedAddress = ({ userAddress, setShowAddAddress }) => (
  <div className='mt-4'>
    <div
      className='max-h-23 overflow-y-auto whitespace-nowrap md:overflow-y-hidden md:h-12.5
  appearance-none w-full cursor-pointer placeholder-default md:overflow-x-scroll no-scrollbar
  bg-white placeholder-lightgray font-medium text-sm leading-5 outline-none p-4 rounded-md shadow-category
   hover:shadow-list'
    >
      {[
        userAddress.houseNumber,
        userAddress.buildingName,
        userAddress.landmark,
        userAddress.formattedAddress
      ]
        .filter((elem) => elem && elem.trim() !== '')
        .join(', ')}
    </div>
    <InputLabel
      className={
        'block text-sm font-Montserrat text-cinnabar font-medium text-right mt-2 me-1 cursor-pointer'
      }
    >
      <span onClick={() => setShowAddAddress(true)}> Change Address </span>{' '}
    </InputLabel>
  </div>
);

export default ShowFormattedAddress;
