import Image from 'next/image';
import PropTypes from 'prop-types';
import Expand from 'react-expand-animated';

import { staticMediaStoreBaseURL } from '@/config/common';

const CheckoutStep = ({
  children,
  currentStep,
  heading,
  isLastTab,
  setCurrentStep,
  stepReached,
  thisStep
}) => {
  const handleClick = () => {
    if (stepReached >= thisStep) {
      setCurrentStep(thisStep);
    }
  };
  return (
    <>
      <div
        className={`cursor-pointer h-15 box-border px-8 py-5 flex items-center justify-between ${
          currentStep === thisStep
            ? 'bg-brand-gradient'
            : 'bg-white border-gradient border-transparent border-solid border'
        } ${isLastTab ? '' : 'border-b-0'}`}
        onClick={handleClick}
      >
        <span
          className={`${
            currentStep === thisStep ? 'text-white' : 'text-dim-gray'
          } text-base font-medium leading-5`}
        >
          {heading}
        </span>

        {currentStep !== thisStep && currentStep > thisStep ? (
          <div className='hidden md:flex items-center justify-center'>
            <span
              className={'text-dim-gray text-sm font-medium leading-5 me-3'}
            >
              Change
            </span>
            {
              <div
                className={`w-3.5 h-3.5 rounded-full flex items-center justify-center bg-brand-gradient transform group-hover:scale-150 transition duration-500 ease-in-out`}
              >
                <Image
                  alt='check'
                  height={6}
                  loading='eager'
                  src={`${staticMediaStoreBaseURL}/icons/check.svg`}
                  width={8}
                />
              </div>
            }
          </div>
        ) : (
          ''
        )}
      </div>
      <Expand
        duration={600}
        open={currentStep === thisStep}
      >
        <div className='bg-white md:bg-lightpink'>
          {thisStep === 1 || currentStep === thisStep ? children : ''}
        </div>
      </Expand>
    </>
  );
};

export default CheckoutStep;

CheckoutStep.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  Headingstyle: PropTypes.string
};
