import Image from 'next/image';
import { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { Modal } from 'reactstrap';

import { Text } from '@/components/atomic/nuclei';
import { staticMediaStoreBaseURL } from '@/config/common';
import {
  getImageURL,
  getRankedImages,
  itemImageDefault
} from '@/helpers/carousel';

const backIcon = `${staticMediaStoreBaseURL}/icons/back-white.svg`;
const closeIcon = `${staticMediaStoreBaseURL}/icons/close-with-border-white.svg`;
const surfaceSpecificPathStub = isMobile ? '/mobile-icons' : '';
const nextIcon = `${staticMediaStoreBaseURL}/thumbnail${surfaceSpecificPathStub}/icons/next.svg`;
const previousIcon = `${staticMediaStoreBaseURL}/thumbnail${surfaceSpecificPathStub}/icons/previous.svg`;

const PreviousNextIcon = ({ images, previousNextIconPair }) =>
  images.length > 1 && (
    <div className='absolute w-full z-101 top-2/4 px-6 md:px-14 flex justify-between text-6xl'>
      {previousNextIconPair.map(
        ({ name, onClick, imageProps: { className, src, alt } }, id) => (
          <a
            key={id}
            {...{
              id: name,
              className: 'cursor-pointer text-white w-9',
              onClick
            }}
          >
            <Image
              {...{
                alt,
                className,
                height: isMobile ? 28 : 36,
                id: name,
                src,
                width: isMobile ? 28 : 36
              }}
            />
          </a>
        )
      )}
    </div>
  );

const ImageTitle = ({ imageTitle }) =>
  imageTitle && (
    <div className='mt-0 md:mt-0 self-center'>
      <h3 className='text-white text-sm md:text-lg font-medium font-Montserrat self-center'>
        {imageTitle}
      </h3>
    </div>
  );

const CarouselModalHeaderSection = ({ imageTitle, openImageModal }) => (
  <div className='absolute px-6 md:px-16 z-101 text-white top-4 mb-2 md:top-4 w-full justify-content-end flex'>
    <div className='md:h-7 flex justify-between w-full'>
      <div className='flex gap-4'>
        <Image
          alt='backIcon'
          className='absolute z-101 h-full cursor-pointer'
          height={isMobile ? 12 : 28}
          onClick={() => openImageModal(false)}
          src={backIcon}
          width={isMobile ? 14 : 16}
        />
        <ImageTitle {...{ imageTitle }} />
      </div>
      <Image
        alt='closeIcon'
        className='absolute z-101 h-full cursor-pointer'
        height={20}
        onClick={() => openImageModal(false)}
        src={closeIcon}
        width={20}
      />
    </div>
  </div>
);

const CarouselModalImage = ({ imageUrl, pageCountTitle }) => (
  <div className='relative flex flex-col items-center md:mx-auto rounded-lg w-full md:w-9/10 h-5/6'>
    <Image
      alt='image'
      className='product-image'
      layout='fill'
      loading='eager'
      objectFit='contain'
      src={imageUrl}
      unoptimized={true}
    />
    <div className='text-white fixed bottom-10 text-lg font-medium z-50'>
      <Text {...{ content: pageCountTitle }} />
    </div>
  </div>
);

const ImageCarouselBody = ({
  handleTouchEnd,
  handleTouchMove,
  handleTouchStart,
  images,
  imageTitle,
  imageUrl,
  openImageModal,
  pageCountTitle,
  previousNextIconPair
}) => (
  <div
    className='fixed z-151 w-full h-full inset-0 bg-black bg-opacity-90 transition-opacity'
    onTouchEnd={handleTouchEnd}
    onTouchMove={handleTouchMove}
    onTouchStart={handleTouchStart}
  >
    <div className='w-full h-full flex flex-col my-20'>
      <CarouselModalHeaderSection {...{ imageTitle, openImageModal }} />
      <PreviousNextIcon {...{ images, previousNextIconPair }} />
      <CarouselModalImage {...{ imageUrl, pageCountTitle }} />
    </div>
  </div>
);

const getIndex = ({ modalImage, images }) =>
  modalImage.rank
    ? modalImage.rank - 1
    : images.findIndex(({ url }) => url === modalImage.url);

const ImageCarouselModal = ({
  images: imagesRaw = [itemImageDefault],
  modalImage = itemImageDefault,
  openImageModal
}) => {
  const images = getRankedImages(imagesRaw);

  const [selectedImageIndex, setSelectedImageIndex] = useState(
    getIndex({ modalImage, images })
  );
  const [image, setImage] = useState(modalImage);

  useEffect(() => {
    const imageRequested = images[selectedImageIndex] || itemImageDefault;
    setImage(imageRequested);
  }, [selectedImageIndex]);

  const previous = () => {
    setSelectedImageIndex((currentIndex) =>
      currentIndex ? currentIndex - 1 : images.length - 1
    );
  };
  const next = () => {
    setSelectedImageIndex((currentIndex) =>
      currentIndex === images.length - 1 ? 0 : currentIndex + 1
    );
  };
  const eventKeyObject = {
    ArrowLeft: () => previous(),
    ArrowRight: () => next(),
    Escape: () => openImageModal(false)
  };

  const keyPressEventAction = ({ key: keyPressed }) =>
    eventKeyObject[keyPressed] && eventKeyObject[keyPressed]();

  useEffect(() => {
    document.addEventListener('keyup', keyPressEventAction);
    return () => document.removeEventListener('keyup', keyPressEventAction);
  }, []);

  let positionStart = 0;
  let positionEnd = 0;
  const handleTouchStart = (event) => {
    positionStart = event.targetTouches[0].clientX;
  };
  const handleTouchMove = (event) => {
    positionEnd = event.targetTouches[0].clientX;
  };
  const handleTouchEnd = (event) => {
    if (!(event.target.id === 'previous' || event.target.id === 'next')) {
      if (positionStart < positionEnd) {
        previous();
      } else {
        next();
      }
    }
  };

  const previousNextIconPair = [
    {
      imageProps: {
        alt: 'previousIcon',
        className: 'text-white z-101',
        src: previousIcon
      },
      name: 'previous',
      onClick: previous
    },
    {
      imageProps: {
        alt: 'nextIcon',
        className: 'z-101',
        src: nextIcon
      },
      name: 'next',
      onClick: next
    }
  ];

  const pageCountTitle = `${image.rank}/${images.length}`;
  const imageUrl = getImageURL(image);

  return (
    imageUrl && (
      <Modal
        centered={true}
        className='p-0 product-image-modal'
        isOpen={true}
        size='xl'
      >
        <ImageCarouselBody
          {...{
            handleTouchEnd,
            handleTouchMove,
            handleTouchStart,
            images,
            imageTitle: image.imageTitle || '',
            imageUrl,
            openImageModal,
            pageCountTitle,
            previousNextIconPair
          }}
        />
      </Modal>
    )
  );
};

export default ImageCarouselModal;
