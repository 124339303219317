import Image from 'next/image';
import { Modal, ModalBody } from 'reactstrap';

import { CloseIcon, Text } from '@/components/atomic/nuclei';
import { staticMediaStoreBaseURL } from '@/config/common';
import phoneConfig from '@/config/phone';

const RequestForCallButton = ({ handleReqCallBackClick }) => (
  <div className='max-w-55 mx-auto block'>
    <button
      className='bg-brand-gradient text-white text-md md:text-base rounded-lg py-3 leading-7 font-medium
          focus:ring-0 focus:outline-none w-full h-12.5 mb-4 hover:shadow-lg transition-opacity inline-flex
          items-center justify-center'
      onClick={handleReqCallBackClick}
    >
      <span className='mt-6 -mr-5'>
        <Image
          {...{
            src: `${staticMediaStoreBaseURL}/icons/req-call-icon.svg`,
            height: 38,
            width: 38,
            alt: 'call-icon'
          }}
        />
      </span>
      <Text
        {...{
          content: 'Request Callback',
          className: 'ms-2',
          HtmlTag: 'span'
        }}
      />
    </button>
  </div>
);

const RequestForCallImage = () => (
  <div className='ml-48'>
    <Image
      {...{
        src: `${staticMediaStoreBaseURL}/icons/timerIcon.svg`,
        height: 100,
        width: 100,
        alt: 'timer-icon'
      }}
    />
  </div>
);

const RequestCallModal = ({
  handleReqCallBackClick,
  isReqCallBackModalVisible,
  setIsReqCallBackModalVisible
}) => (
  <Modal
    isOpen={isReqCallBackModalVisible}
    toggle={() => setIsReqCallBackModalVisible(!isReqCallBackModalVisible)}
    fade={false}
  >
    <ModalBody
      className='bg-white mt-56 w-full md:full md:max-w-122
                      mx-auto md:rounded-lg pb-8 pt-5'
    >
      <CloseIcon
        {...{
          className: 'flex-1 flex flex-row-reverse pe-3 md:pe-4',
          iconClassName: 'text-nero cursor-pointer',
          onClick: () =>
            setIsReqCallBackModalVisible(!isReqCallBackModalVisible)
        }}
      />
      <Text
        {...{
          content: 'THIS IS A PRIORITY ORDER! ',
          className:
            'font-Montserrat text-title:color text-center px-5 pt-2 font-medium text-base md:text-2xl leading-6 block',
          HtmlTag: 'span'
        }}
      />
      <Text
        {...{
          content: 'YOUR EVENT IS WITHIN 24 HOURS',
          className:
            'font-Montserrat text-title:color text-center px-5 font-medium text-base md:text-2xl leading-6 block mb-6',
          HtmlTag: 'span'
        }}
      />
      <RequestForCallImage />

      <Text
        {...{
          content: 'Get in touch with our team now!',
          className: 'text-center text-lg px-5 font-normal mt-6'
        }}
      />
      <Text
        {...{
          content: `We'll help you plan a hassle-free Hafla!`,
          className: 'text-center text-lg px-5 font-normal mb-6'
        }}
      />
      <RequestForCallButton {...{ handleReqCallBackClick }} />

      <Text
        {...{
          content: 'Alternatively, you can reach us on',
          className: 'text-center text-sm px-5 pt-5 font-normal mb-4'
        }}
      />
      <div className='flex gap-3 ml-14'>
        <Image
          {...{
            src: `${staticMediaStoreBaseURL}/icons/call.svg`,
            height: 16,
            width: 16,
            alt: 'call-icon'
          }}
        />
        <Text
          {...{
            content: phoneConfig.phoneNumbers.haflaLandlineMaqsam,
            className: 'mr-6'
          }}
        />
        <Image
          {...{
            src: `${staticMediaStoreBaseURL}/icons/whatsapp-green.svg`,
            height: 22,
            width: 22,
            alt: 'call-icon'
          }}
        />
        {phoneConfig.phoneNumbers.zendeskWhatsappMaqsam}
      </div>
    </ModalBody>
  </Modal>
);

export default RequestCallModal;
