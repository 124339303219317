import Image from 'next/image';

import { Text } from '@/components/atomic/nuclei';
import { staticMediaStoreBaseURL } from '@/config/common';

const steps = [
  { name: 'Event & Contact info', href: '#', status: 'complete' },
  { name: 'Payment', href: '#', status: 'upcoming' }
];

const mergeClassNames = (...classes) => classes.filter(Boolean).join(' ');

const CustomProgressBar = ({ lastStep, paymentStatus, stepReached }) => (
  <nav
    aria-label='Progress'
    className='pb-4 md:hidden'
  >
    <ol
      className='flex items-center justify-center w-full overflow-hidden h-15'
      role='list'
    >
      {steps.map((step, stepIdx) => (
        <li
          className={mergeClassNames(
            stepIdx + 1 !== lastStep ? 'w-1/2' : '',
            'relative'
          )}
          key={step.name}
        >
          {stepIdx + 1 < stepReached ? (
            <>
              <div
                aria-hidden='true'
                className='absolute inset-0 flex items-center'
              >
                <div className='h-px w-full bg-emerald' />
              </div>
              <a
                href='#'
                className={`relative w-4 h-4 flex items-center justify-center bg-white rounded-full transition duration-500 ease-in-out border-2 border-emerald`}
              >
                <Image
                  alt='tick'
                  height={10}
                  src={`${staticMediaStoreBaseURL}/icons/checkmark.svg`}
                  width={10}
                />
                <Text
                  {...{
                    className: 'sr-only',
                    content: step.name,
                    HtmlTag: 'span'
                  }}
                />
              </a>
              <Text
                {...{
                  className:
                    'absolute top-0 left-0 -ml-14 text-center mt-6 w-32 text-xxs font-medium uppercase text-nero',
                  content: step.name
                }}
              />
            </>
          ) : paymentStatus !== 'payment-failed' &&
            stepIdx + 1 == stepReached ? (
            <>
              <div
                aria-hidden='true'
                className='absolute inset-0 flex items-center'
              >
                <div className='h-px w-full bg-dim-gray' />
              </div>
              <a
                aria-current='step'
                className={`relative w-4 h-4 flex items-center justify-center bg-white transition duration-500 ease-in-out border-2 border-emerald rounded-full`}
                href='#'
              >
                <span
                  aria-hidden='true'
                  className='h-2 w-2 bg-emerald rounded-full'
                ></span>
                <Text
                  {...{
                    className: 'sr-only',
                    content: step.name,
                    HtmlTag: 'span'
                  }}
                />
              </a>
              <Text
                {...{
                  className:
                    'absolute top-0 left-0 -ml-14 text-center mt-6 w-32 text-xxs font-medium uppercase text-nero',
                  content: step.name
                }}
              />
            </>
          ) : paymentStatus === 'payment-failed' &&
            stepIdx + 1 == stepReached ? (
            <>
              <div
                aria-hidden='true'
                className='absolute inset-0 flex items-center'
              >
                <div className='h-px w-full bg-dim-gray' />
              </div>
              <a
                aria-current='step'
                className={`relative w-4 h-4 flex items-center justify-center bg-white transition duration-500 ease-in-out border-2 border-error-red rounded-full box-border`}
                href='#'
              >
                <Image
                  alt='close'
                  height={10}
                  src={`${staticMediaStoreBaseURL}/icons/close-red.svg`}
                  width={10}
                />
                <Text
                  {...{
                    className: 'sr-only',
                    content: step.name,
                    HtmlTag: 'span'
                  }}
                />
              </a>
              <Text
                {...{
                  className:
                    'absolute top-0 left-0 -ml-14 text-center mt-6 w-32 text-xxs font-medium uppercase text-error-red',
                  content: 'Failed'
                }}
              />
            </>
          ) : stepIdx + 1 > stepReached ? (
            <>
              <div
                aria-hidden='true'
                className='absolute inset-0 flex items-center'
              >
                <div className='h-px w-full bg-dim-gray' />
              </div>
              <a
                className={`group relative w-4 h-4 flex items-center justify-center bg-white transition duration-500 ease-in-out border-2 border-gray rounded-full hover:border-gray`}
                href='#'
              >
                <span
                  aria-hidden='true'
                  className='h-2 w-2 bg-transparent rounded-full'
                ></span>
                <Text
                  {...{
                    className: 'sr-only',
                    content: step.name,
                    HtmlTag: 'span'
                  }}
                />
              </a>
              <Text
                {...{
                  className:
                    'absolute top-0 -ml-14 text-center mt-6 w-32 text-xxs font-medium uppercase text-nero',
                  content: step.name
                }}
              />
            </>
          ) : (
            ''
          )}
        </li>
      ))}
    </ol>
  </nav>
);

export default CustomProgressBar;
