import Image from 'next/image';
import ReactDatePicker from 'react-datepicker';

import { Text, TimeDropDown } from 'components/atomic/nuclei';
import { staticMediaStoreBaseURL } from 'config/common';
import { getFormattedDate } from 'lib/time';

const EventDateRow = ({
  disabled = false,
  errors,
  handleEventTime,
  selectedEventDate,
  selectedEventStartTime,
  setSelectedEventDate,
  setValue,
  showEventTimeField = true
}) => (
  <div className='grid grid-cols-2 gap-4 items-end mb-3'>
    <div className='w-full flex flex-col h-20'>
      <Text
        {...{
          className: 'text-sm leading-17px font-medium text-nero mb-3',
          content: 'Event Date',
          HtmlTag: 'label'
        }}
      />
      <div className='flex flex-row border-b'>
        <Image
          alt='calendar logo'
          className='cursor-pointer'
          height={18}
          objectFit='initial'
          src={`${staticMediaStoreBaseURL}/icons/calender-grey.svg`}
          width={18}
        />
        <ReactDatePicker
          className='text-sm font-medium leading-5 placeholder-silver cursor-pointer focus:outline-none h-8 p-2 border-0'
          dateFormat='dd/MM/yyyy'
          disabled={disabled}
          name='eventDate'
          onChange={(e) => {
            const formatDate = getFormattedDate({
              date: e,
              format: 'YYYY-MM-DD'
            });
            setValue('eventDate', formatDate);
            setSelectedEventDate(e);
          }}
          popperModifiers={[
            {
              name: 'offset',
              options: {
                offset: [5, 10]
              }
            },
            {
              name: 'preventOverflow',
              options: {
                rootBoundary: 'viewport',
                tether: false,
                altAxis: true
              }
            }
          ]}
          placeholderText='Choose Event Date'
          popperPlacement='top-end'
          scrollableYearDropdown={true}
          selected={selectedEventDate}
          showMonthDropdown={true}
          showYearDropdown={true}
        />
      </div>
      {errors.eventDate && (
        <span className='mt-1 w-full text-red-500 text-xs font-medium capitalize invalid-feedback'>
          {errors.eventDate.message}
        </span>
      )}
    </div>
    {showEventTimeField && (
      <div className='w-full flex flex-col h-20'>
        <Text
          {...{
            className: 'text-sm leading-17px font-medium text-nero pt-1',
            content: 'Event Start Time',
            HtmlTag: 'label'
          }}
        />
        <div className='flex flex-row border-b'>
          <Image
            alt='calendar logo'
            className='cursor-pointer'
            height={18}
            objectFit='initial'
            src={`${staticMediaStoreBaseURL}/icons/clock-grey.svg`}
            width={18}
          />
          <TimeDropDown
            {...{
              className:
                'text-sm font-medium leading-5 placeholder-silver focus:outline-none',
              disabled,
              eventTime: selectedEventStartTime,
              handleEventTime
            }}
          />
        </div>
        {errors.eventTime && (
          <Text
            {...{
              className:
                'mt-1 w-full text-red-500 text-xs font-medium capitalize invalid-feedback',
              content: errors.eventTime.message,
              HtmlTag: 'span'
            }}
          />
        )}
      </div>
    )}
  </div>
);

export default EventDateRow;
