import { useRouter } from 'next/router';

import { Button } from '@/components/atomic/nuclei';
import { getPageURL, ROUTE_CONFIG } from '@/services/hostPortal.service';

const ExitModal = ({
  open,
  setShowExitPopup,
  message,
  discardText,
  confirmtext,
  updateVendorInfo,
  updateInfo
}) => {
  const Router = useRouter();
  const handleExitConfirm = (e) => {
    e.preventDefault();
    const bodyElmStyle = document.getElementsByTagName('body')[0].style;
    bodyElmStyle.removeProperty('height');
    bodyElmStyle.removeProperty('overflow-y');
    setShowExitPopup(false);
    updateVendorInfo(updateInfo);
    Router.push(
      getPageURL({
        pageName: ROUTE_CONFIG.HOME_PAGE.label
      })
    );
  };

  if (open) {
    const bodyElmStyle = document.getElementsByTagName('body')[0].style;
    bodyElmStyle.height = '100vh';
    bodyElmStyle['overflow-y'] = 'hidden';
  }

  return (
    <div
      className='fixed bg-nero bg-opacity-80 transition-opacity z-99 inset-0 md:overflow-y-auto animated fade-in'
      aria-labelledby='Thank you modal'
      role='dialog'
      aria-modal='true'
    >
      <div className='flex items-center min-h-screen justify-center px-5 md:px-0'>
        <div
          className='fixed inset-0 bg-nero bg-opacity-80 transition-opacity'
          aria-hidden='true'
        ></div>

        <div
          className='bg-white rounded-lg shadow-modal md:align-middle md:max-w-100 w-full z-150 overflow-hidden'
          id='supplier-thank-you'
        >
          <div className='p-8'>
            <div className='block max-w-62.5 md:max-w-71.5 mx-auto mb-8 md:mb-10 mt-2'>
              <p className='text-sm md:text-base text-center text-nero font-normal leading-5 md:leading-6'>
                {' '}
                {message}{' '}
              </p>
            </div>

            <div className='flex gap-x-2'>
              <Button
                className={
                  'bg-leadform-gradient h-10 md:h-12.5 py-4 rounded-md text-white text-base font-medium leading-5 w-full max-w-30 md:max-w-50 mx-auto'
                }
                type='submit'
                onClick={() => setShowExitPopup(false)}
              >
                {discardText}
              </Button>

              <Button
                className={`h-10 md:h-12.5 py-4 rounded-md text-nero text-base font-medium leading-5 w-full max-w-30 md:max-w-50 mx-auto border-2 border-transparent hover:border-lightpink`}
                type='submit'
                onClick={handleExitConfirm}
              >
                {confirmtext}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExitModal;
