import Image from 'next/image';

import { Text } from '@/components/atomic/nuclei';
import { staticMediaStoreBaseURL } from '@/config/common';

const NoItemFound = () => (
  <div className='self-center flex text-center gap-2 flex-col justify-center shadow-profile h-126 my-4 rounded-lg bg-white'>
    <div className='opacity-30'>
      <Image
        alt='No Item Found'
        height={80}
        layout='fixed'
        src={`${staticMediaStoreBaseURL}/icons/sad-icon.svg`}
        width={80}
      />
    </div>
    <Text
      className='text-base font-light my-2'
      content='No Item Found'
    />
  </div>
);

export default NoItemFound;
