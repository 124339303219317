import Image from 'next/image';
import ReactDatePicker from 'react-datepicker';

import {
  FormValidationErrorMessage,
  InputLabel,
  TimeDropDown
} from '@/components/atomic/nuclei';
import { staticMediaStoreBaseURL } from '@/config/common';

const DatepickerInput = ({ ...props }) => (
  <input
    type='text'
    {...props}
    readOnly
  />
);

const EventDateTime = ({
  eventDateTime,
  eventTime,
  handleEventDateTimeChange,
  handleEventTime,
  isMobile,
  showEventDateError,
  showEventTimeError
}) => (
  <div
    className='block md:flex w-full space-y-4 md:space-x-7 md:space-y-0 relative mt-4 md:mt-6'
    id='eventDateTime'
  >
    <div className='group w-full md:w-1/2'>
      <InputLabel
        className='text-sm md:text-base font-Montserrat text-nero font-medium mb-2 md:mb-4 block'
        required
      >
        Event Date
      </InputLabel>
      <div
        className={`${
          showEventDateError
            ? 'border border-cinnabar animate-shadow-pulse'
            : 'border-transparent'
        } flex text-sm font-medium rounded-md h-12.5 justify-start items-center shadow-category group-hover:shadow-list bg-white cursor-pointer`}
      >
        <div className='flex items-center ms-3 md:ms-4'>
          {eventDateTime ? (
            <Image
              alt='Event Date'
              height={18}
              src={`${staticMediaStoreBaseURL}/icons/calendar-black.svg`}
              width={18}
            />
          ) : (
            <Image
              alt='Event Date'
              height={18}
              src={`${staticMediaStoreBaseURL}/icons/calender-grey.svg`}
              width={18}
            />
          )}
        </div>
        <ReactDatePicker
          autoComplete='off'
          className='block outline-none w-full text-sm font-medium h-8 p-2 pr-0 rounded for-ios cursor-pointer'
          customInput={<DatepickerInput />}
          dateFormat='dd/MM/yyyy'
          minDate={new Date()}
          name='eventDateTime'
          onChange={handleEventDateTimeChange}
          placeholderText='Choose Event Date'
          popperModifiers={{
            preventOverflow: {
              enabled: true
            }
          }}
          scrollableYearDropdown={true}
          selected={eventDateTime}
          showMonthDropdown={true}
          showYearDropdown={true}
          withPortal={isMobile ? true : ''}
        />
      </div>
      {showEventDateError && (
        <FormValidationErrorMessage
          {...{ message: 'Please choose event date' }}
        />
      )}
    </div>

    <div className='group w-full md:w-1/2'>
      <InputLabel
        className='text-sm md:text-base font-Montserrat text-nero font-medium mb-2 md:mb-4 block'
        required
      >
        Event start time
      </InputLabel>
      <div
        className={`${
          showEventTimeError
            ? 'border border-cinnabar animate-shadow-pulse'
            : 'border-transparent'
        } flex text-silver rounded-md h-12.5 justify-start items-center shadow-category group-hover:shadow-list bg-white cursor-pointer`}
      >
        <div className='flex items-center ms-3 md:ms-4'>
          {eventTime ? (
            <Image
              alt='Event Time'
              height={18}
              src={`${staticMediaStoreBaseURL}/icons/clock-icon-black.svg`}
              width={18}
            />
          ) : (
            <Image
              alt='Event Time'
              height={18}
              src={`${staticMediaStoreBaseURL}/icons/clock-lightgrey.svg`}
              width={18}
            />
          )}
        </div>
        <TimeDropDown
          eventTime={eventTime}
          handleEventTime={handleEventTime}
        />
      </div>
      {showEventTimeError && (
        <FormValidationErrorMessage
          {...{ message: 'Please choose event time' }}
        />
      )}
    </div>
  </div>
);

export default EventDateTime;
