import Image from 'next/image';

import { staticMediaStoreBaseURL } from '@/config/common';

const BottomModal = ({ children, paramData }) => {
  const { handler, hideClose, label, modalHeight, setCloseModal, showHeader } =
    paramData;
  return (
    <div
      className='fixed z-101 w-full h-full inset-0 bg-nero bg-opacity-80 transition-opacity flex items-end'
      id='modal'
      onClick={(e) => {
        e.stopPropagation();
        if (e.target.id === 'modal') {
          setCloseModal(false);
        }
      }}
    >
      <div
        className={`z-120 bg-white w-full md:full md:max-w-152 md:absolute md:left-1/2 md:top-1/2 md:transform md:-translate-x-1/2 md:-translate-y-1/2 ${
          modalHeight || 'min-h-96'
        } mx-auto md:rounded-lg`}
      >
        {showHeader ? (
          <div
            className='z-50 text-black relative flex justify-between p-5 pb-4 md:p-8 md:pb-8'
            onClick={() => handler()}
          >
            <h5 className='font-medium font-Montserrat text-base leading-5 text-nero'>
              {label}
            </h5>
            {!hideClose && (
              <div className='border border-gray box-border flex justify-center bg-white rounded-full w-4 h-4'>
                <Image
                  alt='Close Arrow'
                  className='w-full mx-auto max-w-1.5'
                  height={12}
                  src={`${staticMediaStoreBaseURL}/icons/close-dark.svg`}
                  width={12}
                />
              </div>
            )}
          </div>
        ) : (
          ''
        )}
        {children}
      </div>
    </div>
  );
};

export default BottomModal;
