import Image from 'next/image';

import { staticMediaStoreBaseURL } from '@/config/common';

const SearchInput = ({ heading, searchedValue }) => (
  <input
    autoComplete='off'
    autoFocus
    className='ms-4 focus:bg-transparent outline-none w-full max-w-msearch md:max-w-full text-nero font-Montserrat'
    disabled
    type='text'
    value={heading || searchedValue}
  />
);

const SearchBox = ({
  handleBack,
  heading,
  searchedValue,
  searchResultPage = false,
  setShowSearchModal
}) => (
  <div className='flex md:hidden items-center w-3/5 xxs:w-6.5/10 md:h-3/5'>
    <Image
      alt='Back'
      className='inline-block hafla-header-back cursor-pointer'
      height={13}
      onClick={handleBack}
      src={`${staticMediaStoreBaseURL}/icons/back-angle-black.svg`}
      width={15}
    />
    <div
      className='flex items-center md:block w-full'
      onClick={() => {
        if (searchResultPage) {
          setShowSearchModal(true);
        }
      }}
    >
      {searchResultPage ? (
        <SearchInput {...{ heading, searchedValue }} />
      ) : (
        <div className='ms-2 text-sm md:w-full h-full overflow-hidden text-nero font-Montserrat font-medium'>
          {heading}
        </div>
      )}
    </div>
  </div>
);

export default SearchBox;
