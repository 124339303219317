import Image from 'next/image';
import Link from 'next/link';

import { getPageURL, ROUTE_CONFIG } from '@/services/hostPortal.service';

import { staticMediaStoreBaseURL } from '@/config/common';

const ProfileIconAndText = ({
  onHomeScreenBanner,
  onScrollNotInHomeScreen
}) => (
  <div
    className={`cursor-pointer me-2 hidden md:flex items-center justify-between ${
      onScrollNotInHomeScreen ? 'text-cinnabar' : 'text-white'
    } rounded-6.5 md:max-w-max w-full h-7.5 md:h-10 py-2.5 transition-all`}
  >
    <div className='relative h-7 basic:h-5 xxxs:h-6.5 w-7 basic:w-5 xxxs:w-6.5 md:w-9 md:h-9'>
      <Image
        alt='Login'
        className='cursor-pointer header-icon-size'
        height={33}
        src={`${staticMediaStoreBaseURL}/icons/${
          onHomeScreenBanner ? 'profile-icon.svg' : 'profile-scroll.svg'
        }`}
        width={33}
      />
    </div>

    <div className='ms-2'>
      <div className='text-sm font-bold'>
        Hello,
        <br />
      </div>
      <div className='text-sm'> Sign in </div>
    </div>
  </div>
);

const SignInButton = ({
  onHomeScreenBanner,
  onScrollNotInHomeScreen,
  pathName
}) => (
  <Link
    className='md:block hidden'
    href={getPageURL({
      pageName: ROUTE_CONFIG.LOGIN.label,
      queryParams: {
        redirectTo: pathName
      }
    })}
  >
    <a>
      <ProfileIconAndText
        {...{ onScrollNotInHomeScreen, onHomeScreenBanner }}
      />
    </a>
  </Link>
);

export default SignInButton;
