import { ChargesLabel, Price, PriceLabel } from '@/components/atomic/nuclei';

const DiscountsHostCart = ({ listedDiscountAmount }) =>
  listedDiscountAmount > 0 && (
    <div className='flex justify-between leading-4 pt-3'>
      <ChargesLabel>
        Discounts
        <PriceLabel {...{ isBracket: true }} />
      </ChargesLabel>
      <Price
        {...{
          value: listedDiscountAmount,
          isDiscount: true
        }}
      />
    </div>
  );

export default DiscountsHostCart;
