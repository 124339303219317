import Image from 'next/image';
import { ResetFilters, SearchkitProvider } from 'searchkit';

import { staticMediaStoreBaseURL } from '@/config/common';

const FilterModal = ({ children, paramData, searchkit }) => {
  const { handleBackButtonClick, modalHeight, setCloseModal, showHeader } =
    paramData;

  const handleBack = () => {
    handleBackButtonClick();
    setCloseModal(false);
  };

  const renderClearAll = (props) => {
    const { resetFilters } = props;
    return (
      <div className='w-1/2 text-right'>
        <button
          type='reset'
          onClick={() => {
            resetFilters();
            paramData.clearFilters([]);
          }}
          className='font-Montserrat text-sm leading-5 text-cinnabar font-medium'
        >
          Clear All
        </button>
      </div>
    );
  };

  const renderBackButton = () => (
    <div
      className='w-1/2 flex items-center justify-start'
      onClick={() => handleBack(false)}
    >
      <div className='w-5 h-5 relative'>
        <Image
          src={`${staticMediaStoreBaseURL}/icons/back-arrow-black.svg`}
          layout='fill'
          alt='Back Arrow'
        />
      </div>
      <span className='font-Montserrat text-sm leading-5 text-nero font-medium ms-4'>
        {' '}
        Filter{' '}
      </span>
    </div>
  );

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
        if (e.target.id === 'FilterModal') {
          setCloseModal(false);
        }
      }}
      id='FilterModal'
      className='fixed z-101 w-full h-full inset-0 bg-nero bg-opacity-80 transition-opacity flex'
    >
      <div
        className={`z-120 bg-white w-full ${modalHeight || 'min-h-96'} mx-auto`}
      >
        {showHeader ? (
          <div className='z-50 relative flex justify-between items-center px-5 h-10 shadow-header'>
            <SearchkitProvider searchkit={searchkit}>
              <ResetFilters component={renderBackButton} />
              <ResetFilters component={renderClearAll} />
            </SearchkitProvider>
          </div>
        ) : (
          ''
        )}
        {children}
      </div>
    </div>
  );
};

export default FilterModal;
