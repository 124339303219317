import Image from 'next/image';
import { useEffect, useState } from 'react';

import { Button } from '@/components/atomic/nuclei';
import { staticMediaStoreBaseURL } from '@/config/common';

const SupplierThankyouModal = ({
  buttonText,
  handleClick,
  heading,
  message,
  open
}) => {
  const [show, setShow] = useState(open);
  useEffect(() => {
    setShow(open);
  }, [open]);

  const handleContinueButtonClick = () => {
    const bodyElmStyle = document.getElementsByTagName('body')[0].style;
    bodyElmStyle.removeProperty('height');
    bodyElmStyle.removeProperty('overflow-y');
    setShow(false);
    handleClick();
  };

  if (show) {
    const bodyElmStyle = document.getElementsByTagName('body')[0].style;
    bodyElmStyle.height = '100vh';
    bodyElmStyle['overflow-y'] = 'hidden';
  }

  if (!show) return null;

  return (
    <div
      className='fixed bg-nero bg-opacity-80 transition-opacity z-99 inset-0 md:overflow-y-auto animated fade-in'
      aria-labelledby='Thank you modal'
      role='dialog'
      aria-modal='true'
    >
      <div className='flex items-center min-h-screen justify-center px-5 md:px-0'>
        <div
          className='fixed inset-0 bg-nero bg-opacity-80 transition-opacity'
          aria-hidden='true'
        ></div>

        <div
          className='bg-white rounded-lg shadow-modal md:align-middle md:max-w-120 w-full z-150 overflow-hidden'
          id='supplier-thank-you'
        >
          <div className='p-8'>
            <div className='relative flex items-center justify-center'>
              <div className='w-12 md:w-16 h-12 md:h-16'>
                <Image
                  width={64}
                  height={64}
                  src={`${staticMediaStoreBaseURL}/icons/success.svg`}
                  alt='success'
                />
              </div>
            </div>

            <div className='block max-w-62.5 md:max-w-71.5 mx-auto mb-4 md:mb-6'>
              <h3 className='text-lg md:text-2xl text-center leading-8 font-medium text-nero font-Montserrat mt-4 md:mt-5 mb-2 md:mb-3 block'>
                {heading}
              </h3>

              <p className='text-sm md:text-base text-center text-nero font-normal leading-5 md:leading-6'>
                {' '}
                {message}{' '}
              </p>
            </div>

            <div className='block'>
              <Button
                className={
                  'bg-leadform-gradient h-12.5 py-4 rounded-md text-white text-base font-medium leading-5 w-full max-w-50 mx-auto'
                }
                type='submit'
                onClick={() => handleContinueButtonClick()}
              >
                {buttonText}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SupplierThankyouModal;
