import { TextKeyValuePair } from '@/components/atomic/nuclei';
import { CART_STATUS } from '@/config/common';
import { convertToStandardDateFormat } from '@/lib/time';

const CartInnerBody = ({
  cartCreatedBy,
  createdAt,
  isOrdered,
  orderNumber = ''
}) => {
  const cartKeyValuePairs = [
    { label: `${isOrdered ? 'Cart' : ''} Created By:`, value: cartCreatedBy },
    {
      label: `${isOrdered ? 'Cart' : ''} Created At:`,
      value: convertToStandardDateFormat(createdAt)
    },
    { label: 'Order No.:', value: orderNumber, visible: isOrdered }
  ];
  return (
    <div className='flex flex-wrap gap-10 flex-row gap-y-4 pt-2 md:pt-5 text-sm'>
      {cartKeyValuePairs.map(
        ({ label, value, visible = true }, index) =>
          visible && (
            <TextKeyValuePair
              key={index}
              {...{ label, value }}
            />
          )
      )}
    </div>
  );
};

const CartCardBody = ({ cartCreatedBy, createdAt, orderNumber, status }) => {
  const isOrdered = status === CART_STATUS.ORDER;
  return (
    <div className='flex flex-col md:flex-row justify-between'>
      <CartInnerBody
        {...{
          cartCreatedBy,
          createdAt,
          isOrdered,
          orderNumber
        }}
      />
    </div>
  );
};

export default CartCardBody;
