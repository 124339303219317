import Image from 'next/image';

import { staticMediaStoreBaseURL } from '@/config/common';

const FormValidationErrorMessage = ({ message }) => (
  <div className='flex mt-2 slide-down'>
    <Image
      src={`${staticMediaStoreBaseURL}/icons/error.svg`}
      height={10}
      width={10}
      alt='error-icon'
    />
    <div className='ms-1.5 text-cinnabar text-xs'>{message}</div>
  </div>
);

export default FormValidationErrorMessage;
