import { FormInput } from 'components/atomic/nuclei';

const TRNNumberAndBudgetRow = ({ errors, disabled = false, register }) => (
  <div className='grid grid-cols-2 gap-4 items-end'>
    <FormInput
      {...{
        dbKeyName: 'trnNumber',
        disabled,
        errors,
        label: 'TRN',
        register,
        type: 'text'
      }}
    />
    <FormInput
      {...{
        dbKeyName: 'budget',
        disabled,
        errors,
        label: 'Budget',
        min: 0,
        register,
        type: 'number'
      }}
    />
  </div>
);

export default TRNNumberAndBudgetRow;
