import { useEffect, useState } from 'react';

import timeList from '@/helpers/timeList';

const TimeDropDown = ({
  className,
  disabled = false,
  eventTime,
  handleEventTime
}) => {
  const [time, setTime] = useState('');

  useEffect(() => {
    const selectedOption = timeList.find(({ value }) => value === eventTime);
    setTime(selectedOption);
  }, [eventTime]);

  const DropdownOptions = () =>
    timeList.map((slot, key) =>
      time?.value && slot.time === time.time ? (
        <option
          selected
          className='text-sm font-medium'
          value={slot.value}
          key={key}
        >
          {slot.time}
        </option>
      ) : (
        <option
          className='text-sm font-medium'
          value={slot.value}
          key={key}
        >
          {slot.time}
        </option>
      )
    );

  return (
    <select
      disabled={disabled}
      className={`text-sm ${
        eventTime ? 'text-nero' : 'text-silver'
      } font-medium bg-white outline-none border-0 ms-2 w-full leading-4 cursor-pointer appearance-none py-3 ${className}`}
      onChange={handleEventTime}
    >
      <option
        value=''
        disabled
        selected
      >
        Choose Event Time
      </option>
      <DropdownOptions />
    </select>
  );
};

export default TimeDropDown;
