import { BottomModal, CloseIcon } from '@/components/atomic/nuclei';
import { useIsMobile } from '@/helpers/screenResolutionHelper';

const Disclaimer = () => (
  <div className='md:mt-5'>
    <div className='bg-lightpink p-3 md:p-5 mx-5 md:mx-0 pb-6 md:pb-5'>
      <ul className='text-xs md:text-sm leading-5 md:leading-6 font-normal list-disc ml-5 text-black'>
        <li className='mb-2'>
          The delivery and collection date/time are noted as preferences and for
          our planning purposes only.
        </li>

        <li className='mb-2'>
          The actual date/time may vary based on our event logistics schedule
          and the specific need of your event.
        </li>

        <li className='mb-2'>
          Additional charges* may apply for rentals/service delivery extending
          either the specified hours or more than 12 hours.
        </li>
      </ul>
      <span className='text-10px md:text-xs text-black leading-4 font-normal block'>
        *if applicable, will be discussed with you upon order confirmation.
      </span>
    </div>
  </div>
);

function CheckoutDisclaimerModal({ setShowDisclaimerModal }) {
  const [isMobile] = useIsMobile();

  return isMobile ? (
    <BottomModal
      paramData={{
        handler: () => setShowDisclaimerModal(false),
        label: 'Please Note:',
        modalHeight: 'min-h-86',
        setCloseModal: setShowDisclaimerModal,
        showHeader: true
      }}
    >
      <Disclaimer />
    </BottomModal>
  ) : (
    <div
      id='checkout-disclaimer-modal'
      className='fixed z-101 w-full h-full inset-0 bg-nero bg-opacity-80 transition-opacity flex items-center md:animated md:fade-in'
      onClick={(e) => {
        e.stopPropagation();
        if (e.target.id === 'checkout-disclaimer-modal') {
          setShowDisclaimerModal(false);
        }
      }}
    >
      <div className='z-50 bg-white w-full max-w-156 mx-auto relative rounded-md p-5 md:p-6'>
        <div
          className='text-black relative flex justify-between'
          onClick={() => setShowDisclaimerModal(false)}
        >
          <h5 className='font-medium font-Montserrat text-sm md:text-base leading-5 text-nero'>
            Please Note:
          </h5>

          <CloseIcon {...{ className: 'cursor-pointer', loading: 'eager' }} />
        </div>

        <Disclaimer />
      </div>
    </div>
  );
}

export default CheckoutDisclaimerModal;
