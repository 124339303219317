import Image from 'next/image';
import Link from 'next/link';

import { staticMediaStoreBaseURL } from '@/config/common';
import { getPageURL, ROUTE_CONFIG } from '@/services/hostPortal.service';

const LoginHeader = () => {
  const logoUrl = '/logos/hafla_new.svg';

  return (
    <div className='flex md:hidden justify-between md:h-18 h-12 py-3 z-40 md:px-20 px-5 w-full items-center shadow-lg sticky top-0 bg-white'>
      <div className='flex items-center w-3/5'>
        <Link
          className='cursor-pointer'
          href={getPageURL({
            pageName: ROUTE_CONFIG.HOME_PAGE.label
          })}
        >
          <Image
            width={50}
            height={16}
            id='login-header-image'
            src={`${staticMediaStoreBaseURL}${logoUrl}`}
            alt='Hafla'
            className='inline-block hafla-header-img md:26px cursor-pointer'
          />
        </Link>
      </div>
    </div>
  );
};

export default LoginHeader;
