import Image from 'next/image';

import { Button, Text } from '@/components/atomic/nuclei';
import { staticMediaStoreBaseURL } from '@/config/common';

const PaymentFailureModalMobile = ({ handlePaymentFailed }) => (
  <div className='w-full mx-auto h-screen flex items-start'>
    <div className='w-full max-w-68 md:max-w-100 flex flex-col items-center mx-auto mt-20'>
      <Text
        {...{
          className:
            'text-nero text-lg md:text-2xl font-medium leading-7 font-Montserrat',
          content: 'Payment Failed',
          HtmlTag: 'h4'
        }}
      />

      <div className='group my-6 md:my-12'>
        <div
          className='w-20 h-20 md:w-28 md:h-28 cursor-pointer border-2 border-error-red rounded-full bg-lightpink
          flex justify-center items-center'
        >
          <Image
            {...{
              width: 32,
              height: 32,
              src: `${staticMediaStoreBaseURL}/icons/failed.svg`,
              alt: 'failed'
            }}
          />
        </div>
      </div>

      <div className='flex flex-col items-center mb-10 md:mb-8'>
        <Text
          {...{
            className:
              'text-sm text-center md:text-base font-normal text-dim-gray leading-5 md:leading-6',
            content: 'Payment didn’t go through, Please try again.'
          }}
        />
      </div>

      <Button
        {...{
          className: `bg-brand-gradient text-white text-sm md:text-base rounded-lg py-3 leading-7 font-medium focus:ring-0
          focus:outline-none w-full h-12.5 mb-4 hover:shadow-lg transition-opacity`,
          onClick: handlePaymentFailed,
          children: 'Try Again'
        }}
      />
    </div>
  </div>
);

export default PaymentFailureModalMobile;
