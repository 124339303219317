import Image from 'next/image';

import { Button, CloseIcon, Text } from '@/components/atomic/nuclei';
import { staticMediaStoreBaseURL } from '@/config/common';

const ErrorTitle = () => (
  <div className='flex items-center mb-2'>
    <div className='relative flex me-2.5'>
      <Image
        className='cursor-pointer'
        src={`${staticMediaStoreBaseURL}/icons/failed.svg`}
        width={20}
        height={20}
        alt='close'
      />
    </div>
    <Text
      {...{
        HtmlTag: 'h5',
        className: 'font-medium font-Montserrat text-base leading-5',
        content: 'Payment failed'
      }}
    />
  </div>
);

const ErrorMessage = ({ paymentFailMessage }) => (
  <div className='mt-2 md:ms-7.5 text-center md:text-left'>
    <Text
      {...{
        className: 'font-normal text-sm leading-4',
        content: paymentFailMessage
      }}
    />
  </div>
);

const PaymentFailureModalWeb = ({
  handlePaymentFailed,
  handlePaymentFailedModalClose,
  setCloseModal,
  paymentFailMessage = 'Payment didn’t go through, Please try again.'
}) => (
  <div
    id='payment-failed'
    className='fixed z-101 w-full h-full inset-0 bg-nero bg-opacity-80 transition-opacity flex items-center animated fade-in'
    onClick={(e) => {
      e.stopPropagation();
      if (e.target.id === 'payment-failed') {
        return setCloseModal(false);
      }
      return null;
    }}
  >
    <div className='z-50 bg-white w-full max-w-156 mx-auto relative rounded-md p-5 md:p-7.5'>
      <CloseIcon
        {...{
          className: 'z-50 absolute right-4 top-4',
          iconClassName: 'cursor-pointer',
          onClick: handlePaymentFailedModalClose
        }}
      />

      <div className='text-nero mb-8'>
        <ErrorTitle />
        <ErrorMessage {...{ paymentFailMessage }} />
      </div>

      <Button
        {...{
          className: `max-w-60 mx-auto bg-brand-gradient h-13 py-4 rounded-md text-white text-base font-medium leading-5 w-full hover:shadow-xl`,
          onClick: handlePaymentFailed,
          children: 'Try Again'
        }}
      />
    </div>
  </div>
);

export default PaymentFailureModalWeb;
