import Image from 'next/image';
import Link from 'next/link';

import { staticMediaStoreBaseURL } from '@/config/common';

const Breadcrumbs = ({ breadCrumbs }) => (
  <div className='w-full flex gap-1 ms-1'>
    {breadCrumbs.map((c, i) => (
      <div
        className='flex flex-row justify-center items-center'
        key={i}
      >
        {i > 0 && (
          <div className='px-1 font-base text-dim-gray flex justify-items-center me-1.5'>
            <Image
              alt='right arrow'
              height={10}
              objectFit='initial'
              src={`${staticMediaStoreBaseURL}/icons/right-angle.svg`}
              width={10}
            />
          </div>
        )}
        <Link href={c.link}>
          <a
            className={`me-1.5 font-base h-full text-xs font-medium font-Montserrat hover:text-coral ${
              i === breadCrumbs.length - 1 ? 'text-nero' : 'text-dim-gray'
            }`}
          >
            {c.name}
          </a>
        </Link>
      </div>
    ))}
  </div>
);

export default Breadcrumbs;
