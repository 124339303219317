import {
  FormValidationErrorMessage,
  InputLabel
} from '@/components/atomic/nuclei';

const DeliveryAddress = ({ showAddressError }) => (
  <div className='block md:flex md:items-center md:mb-4'>
    <div>
      <InputLabel
        className='block text-sm md:text-base font-Montserrat text-nero font-medium'
        required
      >
        Delivery Address
      </InputLabel>
    </div>
    <div className='md:ms-3 pb-1.5'>
      {showAddressError && (
        <FormValidationErrorMessage
          {...{ message: 'Please choose delivery address.' }}
        />
      )}
    </div>
  </div>
);

export default DeliveryAddress;
