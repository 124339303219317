import Image from 'next/image';
import { useRouter } from 'next/router';

import { useUIContext } from '@/components/context/uiContext/ManagedUiContext';
import { DEVICE_SURFACE, staticMediaStoreBaseURL } from '@/config/common';
import { captureGTMEventWhatsappClick } from '@/lib/gtm';
import { getPlanEventWithHebaWhatsappURL } from '@/services/planEventWithHeba.service';

const WhatsappSupportIcon = () => {
  const { surface } = useUIContext();
  const isMobile = surface === DEVICE_SURFACE.MOBILE;

  const router = useRouter();

  const openWhatsapp = async () => {
    captureGTMEventWhatsappClick();
    router.push(getPlanEventWithHebaWhatsappURL({ router }));
  };

  return (
    <div
      className='flex items-center cursor-pointer relative mx-3 md:ml-6 md:mr-4'
      onClick={openWhatsapp}
    >
      <Image
        src={`${staticMediaStoreBaseURL}/icons/whatsapp-green.svg`}
        height={isMobile ? 24 : 33}
        width={isMobile ? 24 : 33}
        layout='fixed'
        alt='Whatsapp'
      />
    </div>
  );
};

export default WhatsappSupportIcon;
