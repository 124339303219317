// TODO: Hardik/Faizan cleanup pending
import config from '@/config';
import _ from 'lodash';

import { SOURCE_ORIGIN } from 'config/common';
import phoneConfig from 'config/phone';
import {
  BOOKING_PORTAL_URL,
  CORPORATE_PORTAL_URL,
  HOST_PORTAL_URL,
  WEDDINGS_PORTAL_URL
} from 'services/connections.service';
import {
  COOKIE_MAP,
  getCLIDFromCookie,
  getCookie,
  getMSCLKIDFromCookie,
  setCookie
} from 'services/cookie.service';
import { getPageURL, ROUTE_CONFIG } from 'services/hostPortal.service';
import { getUserAuth } from 'services/identity.service';
import { getURLPathnameWithoutQuery } from 'services/url.service';
import { createLeadMarketingReferences } from 'services/user.service';

const { hebaWhatsapp } = phoneConfig.phoneNumbers;

const sourceOriginMap = {
  [SOURCE_ORIGIN.BOOKING_PORTAL]: BOOKING_PORTAL_URL,
  [SOURCE_ORIGIN.CORPORATE_PORTAL]: CORPORATE_PORTAL_URL,
  [SOURCE_ORIGIN.HOST_PORTAL]: HOST_PORTAL_URL,
  [SOURCE_ORIGIN.WEDDINGS_PORTAL]: WEDDINGS_PORTAL_URL
};

const getLeadMarketingReferencesFromUserAuth = ({ userAuth }) => ({
  guestUserId: userAuth?.isGuest ? userAuth.id : null,
  hostId: userAuth?.isGuest ? null : userAuth?.id
});

const getLeadMarketingReferencesFromQueryParameters = ({ query }) => ({
  fbclid: query.fbclid,
  gclid: query.gclid,
  liid: query.li_fat_id,
  msclkid: query.msclkid,
  scclid: query.scclid,
  sourceOrigin: query.sourceOrigin || SOURCE_ORIGIN.HOST_PORTAL,
  sourcePathname: query.sourcePathname,
  ttclid: query.ttclid,
  twclid: query.twclid,
  utmCampaign: query.utm_campaign,
  utmContent: query.utm_content,
  utmMedium: query.utm_medium,
  utmSource: query.utm_source,
  utmTerm: query.utm_term,
  ytclid: query.ytclid
});

const getLeadMarketingReferencesFromCookie = () => ({
  fbclid: getCLIDFromCookie({ name: COOKIE_MAP.FBP }),
  gclid: getCLIDFromCookie({ name: COOKIE_MAP.GCL }),
  liid: getCLIDFromCookie({ name: COOKIE_MAP.LIID }),
  msclkid: getMSCLKIDFromCookie(),
  scclid: getCLIDFromCookie({ name: COOKIE_MAP.SCLID }),
  ttclid: getCLIDFromCookie({ name: COOKIE_MAP.TTCLID })
});

const removeNullUndefinedEmptyStringValuesFromJSONObject = (obj) => {
  if (_.isArray(obj)) {
    return obj
      .map(removeNullUndefinedEmptyStringValuesFromJSONObject)
      .filter((v) => !_.isNil(v) && v !== '');
  }
  if (_.isObject(obj)) {
    return _.omitBy(
      _.mapValues(obj, removeNullUndefinedEmptyStringValuesFromJSONObject),
      (v) => _.isNil(v) || v === ''
    );
  }
  return obj;
};

const getLeadMarketingReferenceNumber = async ({ query, setShowToast }) => {
  const userAuth = getUserAuth();
  const sourceOrigin = query.sourceOrigin || SOURCE_ORIGIN.HOST_PORTAL;

  const [
    leadMarketingReferencesFromQuery,
    leadMarketingReferencesFromCookie,
    leadMarketingReferencesFromUserAuth
  ] = [
    getLeadMarketingReferencesFromQueryParameters({ query }),
    getLeadMarketingReferencesFromCookie(),
    getLeadMarketingReferencesFromUserAuth({ userAuth })
  ].map((leadMarketingReferences) =>
    removeNullUndefinedEmptyStringValuesFromJSONObject(leadMarketingReferences)
  );

  const leadMarketingReferences =
    sourceOrigin === SOURCE_ORIGIN.HOST_PORTAL
      ? {
          ...leadMarketingReferencesFromUserAuth,
          ...leadMarketingReferencesFromCookie,
          ...leadMarketingReferencesFromQuery
        }
      : leadMarketingReferencesFromQuery;

  const {
    referenceNumber: storedReferenceNumber,
    ...storedLeadMarketingReferences
  } =
    getCookie({
      name: COOKIE_MAP.LEAD_MARKETING_REFERENCES
    }) || {};

  const reuseLeadMarketingReference =
    storedReferenceNumber &&
    _.isEqual(storedLeadMarketingReferences, leadMarketingReferences);

  if (reuseLeadMarketingReference) {
    return storedReferenceNumber;
  }

  try {
    const { entity, message, status } = await createLeadMarketingReferences({
      leadMarketingReferences
    });
    const referenceNumber = entity?.leadMarketingReferenceNumber;
    const failToGetLeadMarketingReference = !status || !referenceNumber;
    if (failToGetLeadMarketingReference) {
      setShowToast({
        message: `Failed to create Lead Marketing Reference : ${message}`,
        show: true,
        status: false
      });
      return null;
    }

    setCookie({
      data: { ...leadMarketingReferences, referenceNumber },
      name: COOKIE_MAP.LEAD_MARKETING_REFERENCES
    });

    return referenceNumber;
  } catch (error) {
    setShowToast({
      message: `Failed to create Lead Marketing Reference : ${error.message}`,
      show: true,
      status: false
    });
  }

  return null;
};

const getLeadMarketingReferenceTracker = async ({
  leadMarketingReferenceNumber
}) => `\n\nReferenceCode: ${leadMarketingReferenceNumber ?? '-'}\n`;

const getWelcomeMessageForBotpressHeba = ({ marketingLeadSuffix, query }) => {
  const showFullURL = false;
  const pathname = query.sourcePathname || '';
  const sourceOrigin = query.sourceOrigin || SOURCE_ORIGIN.HOST_PORTAL;

  const pageURLWithoutQueryParam = `${sourceOriginMap[sourceOrigin]}${pathname}`; // TODO: Hardik - we may have to include entity id and state var, if present in param, and exclude only tracking query param i.e. utm_param and clid identifier & sourceOrigin will still be excluded as we inject it in base path via sourceOriginMap[sourceOrigin]
  const searchString = query.toString(); // TODO: Hardik - we should will still exclude sourceOrigin, sourcePathname - as they were facilitators for us
  const encodedQueryString =
    searchString.length > 0 ? `, ${encodeURIComponent(searchString)}` : '';
  const fullURL = showFullURL
    ? `\n\n${pageURLWithoutQueryParam}${encodedQueryString}`
    : '';

  const messagePrefix = config.isProduction
    ? ''
    : `Please Ignore this - this message is from the non production portal. \n\n`;
  return `${messagePrefix}Hi Hafla! I have a query on - ${pageURLWithoutQueryParam}.${fullURL}${marketingLeadSuffix}`;
};

const generateWelcomeMessageForBotpressHeba = async ({
  query,
  setShowToast
}) => {
  const leadMarketingReferenceNumber = await getLeadMarketingReferenceNumber({
    query,
    setShowToast
  });

  const marketingLeadSuffix = await getLeadMarketingReferenceTracker({
    leadMarketingReferenceNumber
  });

  const formattedMessage = getWelcomeMessageForBotpressHeba({
    marketingLeadSuffix,
    query
  });

  return formattedMessage;
};

export const sendBotpressHebaWelcomeMessageToWebchat = async ({
  query,
  setIsLeadMarketingReferencePosted,
  setShowToast
}) => {
  const welcomeMessage = await generateWelcomeMessageForBotpressHeba({
    query,
    setShowToast
  });
  let intervalId;
  const attemptSendingMessageTillSuccess = async () => {
    try {
      await window.botpress.sendMessage(welcomeMessage);
      setIsLeadMarketingReferencePosted(true);
      clearInterval(intervalId);
    } catch (e) {
      console.error(
        'heba error in botpress.sendMessage - window.botpress',
        window.botpress,
        e?.message
      );
    }
  };
  intervalId = setInterval(attemptSendingMessageTillSuccess, 1500);
};

export const getBotpressHebaWABARedirectURL = async ({
  query,
  setShowToast
}) => {
  const whatsAppBaseURL = 'https://api.whatsapp.com/send';

  const formattedMessage = await generateWelcomeMessageForBotpressHeba({
    query,
    setShowToast
  });

  return `${whatsAppBaseURL}?phone=${hebaWhatsapp}&text=${encodeURIComponent(
    formattedMessage
  )}`;
};

export const getPlanEventWithHebaWhatsappURL = ({ router }) => {
  const { asPath, query } = router;
  const sourcePathname = getURLPathnameWithoutQuery({ asPath });

  const planEventWithHebaURL = getPageURL({
    pageName: ROUTE_CONFIG.PLAN_EVENT_WITH_HEBA_WHATSAPP.label,
    queryParams: {
      ...query,
      sourceOrigin: SOURCE_ORIGIN.HOST_PORTAL,
      sourcePathname
    }
  });

  return planEventWithHebaURL;
};

export const getPlanEventWithHebaURL = ({ router }) => {
  const { asPath, query } = router;
  const sourcePathname = getURLPathnameWithoutQuery({ asPath });

  // const pageName = config.isProduction
  //   ? ROUTE_CONFIG.PLAN_EVENT_WITH_HEBA.label
  //   : ROUTE_CONFIG.PLAN_EVENT_WITH_HEBA_WEBCHAT.label,

  const planEventWithHebaURL = getPageURL({
    pageName: ROUTE_CONFIG.PLAN_EVENT_WITH_HEBA.label,
    queryParams: {
      ...query,
      sourceOrigin: SOURCE_ORIGIN.HOST_PORTAL,
      sourcePathname
    }
  });

  return planEventWithHebaURL;
};
