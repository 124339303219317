import { Text } from '@/components/atomic/nuclei';

const ListingCartNotification = ({ alertMessage }) => (
  <div className='fixed bottom-10 left-1/2'>
    <div className='cursor-pointer z-99 w-full max-w-94'>
      <div className='h-13 rounded-md bg-black px-2.5 basic:px-8 py-3 mx-5 md:mx-0 overflow-hidden flex items-center justify-between space-x-7'>
        <Text
          {...{
            className: 'text-white text-sm font-normal',
            content: alertMessage,
            HtmlTag: 'span'
          }}
        />
      </div>
    </div>
  </div>
);

export default ListingCartNotification;
