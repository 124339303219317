import { ChargesLabel, Price, PriceLabel } from '@/components/atomic/nuclei';

const ItemTotalHostCart = ({ sellingPriceAmount }) => (
  <div className='flex justify-between leading-4 pt-3'>
    <ChargesLabel>
      Item total
      <PriceLabel {...{ isBracket: true }} />
    </ChargesLabel>
    <Price {...{ value: sellingPriceAmount }} />
  </div>
);

export default ItemTotalHostCart;
