const DynamicComponent = ({ ComponentName, props, ...rest }) => (
  <ComponentName {...{ ...props, ...rest }} />
);
const FieldsComponent = ({ fieldsWithProps, ...rest }) =>
  fieldsWithProps.map((item, index) => (
    <DynamicComponent
      key={index}
      {...{
        ...rest,
        ...item
      }}
    />
  ));

export default FieldsComponent;
