import 'react-datepicker/dist/react-datepicker.css';

import { FormInput } from 'components/atomic/nuclei';

const FormInputView = ({
  additionalStyleClass,
  dbKeyName,
  disabled = false,
  errors,
  label,
  register,
  type = 'text'
}) => (
  <FormInput
    {...{
      additionalStyleClass,
      dbKeyName,
      disabled,
      disabledBg: disabled,
      errors,
      label,
      register,
      type
    }}
  />
);

export default FormInputView;
