import Image from 'next/image';

import { Text } from '@/components/atomic/nuclei';
import { staticMediaStoreBaseURL } from '@/config/common';

const LogoutConfirmationModal = ({
  logOut,
  setShowLogoutConfirmationModal
}) => (
  <div
    aria-labelledby='modal-title'
    aria-modal='true'
    className='fixed z-50 inset-0 overflow-y-auto md:animated md:fade-in'
    role='dialog'
  >
    <div className='flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
      <div
        aria-hidden='true'
        className='fixed inset-0 bg-nero bg-opacity-80 transition-opacity'
      ></div>

      <span
        aria-hidden='true'
        className='hidden sm:inline-block sm:align-middle sm:h-screen'
      >
        &#8203;
      </span>

      <div
        className='inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl
        transform transition-all sm:align-middle w-full mx-auto px-15 md:px-17.5 py-8 md:py-12 md:w-100'
      >
        <div
          className='absolute top-4 right-4 z-50 text-black flex justify-end cursor-pointer'
          onClick={() => setShowLogoutConfirmationModal(false)}
        >
          <Image
            alt='close icon'
            className='text-black'
            height={15}
            src={`${staticMediaStoreBaseURL}/icons/close-gray.svg`}
            width={15}
          ></Image>
        </div>
        <div className='w-9/10 mx-auto'>
          <div className='bg-white w-full'>
            <div className='text-center'>
              <Text
                {...{
                  className:
                    'text-base md:text-xl leading-6 w-full font-medium text-gray-900',
                  content: 'Are you sure you want to logout of your account?',
                  HtmlTag: 'h3',
                  id: 'modal-title'
                }}
              />
            </div>
          </div>
          <div className='pt-9 mx-auto flex flex-row-reverse justify-between items-center'>
            <button
              type='button'
              onClick={() => {
                logOut();
                setShowLogoutConfirmationModal(false);
              }}
              className='text-sm md:text-base bg-coral text-white rounded-lg p-4 leading-4
                font-medium focus:outline-none focus:ring-2 focus:ring-offset-2'
            >
              Confirm
            </button>
            <button
              type='button'
              onClick={() => setShowLogoutConfirmationModal(false)}
              className='justify-center rounded-md px-4 py-2 text-sm md:text-base font-medium text-gray-700 hover:bg-platinum-50
                focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ms-3 sm:w-auto sm:text-sm'
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default LogoutConfirmationModal;
