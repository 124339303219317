import { ChargesLabel, Price, PriceLabel } from '@/components/atomic/nuclei';

const AdditionalChargeHostCart = ({ additionalChargeAmount }) =>
  additionalChargeAmount > 0 && (
    <div className='flex justify-between leading-4 pt-3'>
      <ChargesLabel>
        Additional Charges
        <PriceLabel {...{ isBracket: true }} />
      </ChargesLabel>
      <Price value={additionalChargeAmount} />
    </div>
  );

export default AdditionalChargeHostCart;
