const TextKeyValuePair = ({
  className = 'flex-col',
  label,
  labelClass = 'text-silver uppercase text-xs md:text-sm',
  spaceTop = 'mt-2 md:mt-0',
  value,
  valueClassName = 'text-base md:text-lg'
}) => (
  <div className={`flex gap-2 ${className} ${spaceTop}`}>
    <div className={labelClass}>{label}</div>
    <div className={valueClassName}>{value}</div>
  </div>
);

export default TextKeyValuePair;
