import { FormValidationErrorMessage } from '@/components/atomic/nuclei';

const ContactDetailInputFields = ({ inputFields, onChange }) => (
  <div className='block md:flex w-full space-y-4 md:space-x-7 md:space-y-0'>
    {inputFields.map(
      (
        { defaultValue, errorField, errorMessage, name, placeholder },
        index
      ) => (
        <div
          className='w-full md:w-1/2'
          key={`${name}-${index}`}
        >
          <input
            {...{
              autoComplete: 'off',
              className: ` ${
                errorField
                  ? 'border border-cinnabar animate-shadow-pulse'
                  : 'border-transparent'
              } appearance-none md:max-h-12.5 w-full cursor-pointer placeholder-default text-nero
                font-medium text-sm leading-5 outline-none p-4 rounded-md shadow-category md:shadow-input
                md:hover:shadow-list overflow-x-auto no-scrollbar}`,
              defaultValue,
              name,
              onChange,
              placeholder
            }}
          />
          {errorField && (
            <FormValidationErrorMessage {...{ message: errorMessage }} />
          )}
        </div>
      )
    )}
  </div>
);

export default ContactDetailInputFields;
