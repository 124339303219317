import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import { captureGTMEventPageView } from '@/lib/gtm';

const googleAnalyticsTag =
  process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_MEASUREMENT_ID;

const ENABLE_PAGE_VIEW_EVENT_CAPTURE_VIA_GTM = false;
const ENABLE_PAGE_VIEW_EVENT_CAPTURE_VIA_LEGACY_GOOGLE_ANALYTICS = false;

const usePageViewTrackingViaLegacyGoogleAnalytics = ({ router }) => {
  const skipRecording =
    !ENABLE_PAGE_VIEW_EVENT_CAPTURE_VIA_LEGACY_GOOGLE_ANALYTICS ||
    !googleAnalyticsTag;

  useEffect(() => {
    if (skipRecording) return undefined;

    const googleAnalyticsPageViewLegacy = (urlPathQueryFragment) => {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push(['js', new Date()]);
      window.dataLayer.push([
        'config',
        googleAnalyticsTag,
        { page_path: urlPathQueryFragment }
      ]);
    };

    router.events.on('routeChangeComplete', googleAnalyticsPageViewLegacy);
    return () => {
      router.events.off('routeChangeComplete', googleAnalyticsPageViewLegacy);
    };
  }, [router.events]);
};

const usePageViewTrackingViaGTM = ({ router }) => {
  const [firedPageView, setFiredPageView] = useState(false);

  const skipRecording =
    !ENABLE_PAGE_VIEW_EVENT_CAPTURE_VIA_GTM || firedPageView || !router?.asPath;

  useEffect(() => {
    if (skipRecording) return;

    captureGTMEventPageView(router.asPath);
    setFiredPageView(true);
  }, [firedPageView, router?.asPath]);
};

const useRecordPageViewEvent = () => {
  const router = useRouter();

  usePageViewTrackingViaLegacyGoogleAnalytics({ router });
  usePageViewTrackingViaGTM({ router });
};
const RecordPageViewEvent = ({ children }) => {
  useRecordPageViewEvent();
  return children;
};

export default RecordPageViewEvent;

/*
TO BE REFINED

Path ahead - Identify & streamline all cases where page navigation happens - in sequence

1. <a></a> (no intermediate children)
2. <a></Image></a> (no other intermediate children)
3. <a><Link></Link></a>
4. <a>children</a> (CustomAnchor) (will solve this and above 3)
5. <Link></Link> OR <Link>{children}</Link> :: Sidd to implement CustomLink
6. (non existent - to check) <Link><a></a></Link> (or where <Link> <new component from above 2 cases> </Link>)
---
<a 150 in 78 (249/139)
<Link 137 in 69 (199/106)
<Image 367 in 196 (576/288)

instead of next/link and anchor(without images as child) use this
const CustomLink = ({ href, children, ...rest }) => {
 const currentParams = new URLSearchParams(window.location.search);
 const newParams = new URLSearchParams(href);
 TODO: this is slightly incorrect as the new params should not be over-written by old - so need to fix
 currentParams.forEach((value, key) => newParams.set(key, value));
 return <Link href={`${href}?${newParams}`} {...rest}>{children}</Link>;
};

use this in combinition of above mentioned - wherever we are wrapping anchor on top of next/image
<div className="clickable-image" onClick={() => router.push('/other-page')}>
 <Image src="/your-image.jpg" alt="Image description" width={500} height={300} />
</div>
----
Partner portal cases

const CustomAnchor = ({ href, onclick, target, children, ...restAnchorProps}) =>

ClickableImage -- <a { ...{href, onclick, target, ...rest}} ><Image><Image><a> ===> <span >
will also be solved with CustomAnchor
*/
