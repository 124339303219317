import { PriceLabel } from '@/components/atomic/nuclei';
import { parseFormatPriceValueFromAPI } from '@/lib/numberStringUtils';

const OrderTotalHostCart = ({ orderTotal }) => (
  <div className='flex justify-between font-medium text-nero leading-4 pt-3'>
    <div className='flex flex-col'>
      <span className='leading-4 text-nero font-medium text-sm md:text-base'>
        Grand total
        <PriceLabel {...{ isBracket: true }} />
      </span>
    </div>
    <span className='font-medium text-sm'>
      {parseFormatPriceValueFromAPI(orderTotal)}
    </span>
  </div>
);

export default OrderTotalHostCart;
