import Image from 'next/image';
import Link from 'next/link';
import { useEffect, useState } from 'react';

import { Text } from '@/components/atomic/nuclei';
import { staticMediaStoreBaseURL } from '@/config/common';
import { captureGTMEventLogOut } from '@/lib/gtm';
import { getPageURL, ROUTE_CONFIG } from '@/services/hostPortal.service';
import { getAgentInfo } from '@/services/identity.service';

const MenuItem = ({ pageName, title }) => (
  <Link
    href={getPageURL({
      pageName
    })}
  >
    <Text
      {...{
        className: 'ps-2 py-1 text-dim-gray cursor-pointer',
        content: title,
        HtmlTag: 'span'
      }}
    />
  </Link>
);

const LogOutMenuItem = ({ logOut }) => (
  <Text
    {...{
      className: 'ps-2 py-1 text-dim-gray cursor-pointer',
      content: 'Log Out',
      onClick: () => {
        captureGTMEventLogOut();
        logOut();
      },
      HtmlTag: 'span'
    }}
  />
);

const UserName = ({ isScrolling, name }) => (
  <Text
    {...{
      className: `${
        isScrolling ? 'text-white' : 'text-brand'
      } ms-2 text-sm w-15 overflow-hidden font-medium relative inline-block no-underline overflow-ellipsis whitespace-nowrap`,
      content: name || '',
      HtmlTag: 'span'
    }}
  />
);

const dropdownMenuConfig = [
  {
    pageName: ROUTE_CONFIG.USER_PROFILE.label,
    title: 'Profile'
  },
  {
    pageName: ROUTE_CONFIG.USER_PROFILE_MY_EVENTS.label,
    title: 'My Events'
  },
  {
    pageName: ROUTE_CONFIG.MY_ORDERS.label,
    title: 'My Orders'
  }
];
const DropdownMenu = ({ context, logOut, setShowAccountOptions }) => (
  <>
    <div
      {...{
        onClick: (e) => {
          e.stopPropagation();
          if (e.target.id === 'modal') {
            setShowAccountOptions(false);
          }
        },
        id: 'modal',
        className:
          'fixed z-40 w-full h-full inset-0 flex md:items-center items-end'
      }}
    ></div>
    <div
      {...{
        className:
          'border border-platinum rounded-lg flex flex-col absolute z-50 top-10 w-36 bg-white p-2 mt-1'
      }}
    >
      {dropdownMenuConfig.map(({ pageName, title }) => (
        <MenuItem
          key={pageName}
          {...{ pageName, title }}
        />
      ))}
      <LogOutMenuItem {...{ ...context, logOut }} />
    </div>
  </>
);

const AccountMenu = ({ logOut, onHomeScreenBanner, ...context }) => {
  const [showAccountOptions, setShowAccountOptions] = useState(false);
  const [agentInfo, setAgentInfo] = useState({});
  const { agent } = agentInfo;
  const title =
    agent &&
    `Username: ${agent.name}\nUser ID: ${agentInfo.id}\nAgent: ${agent.email}`;

  const icon = `${staticMediaStoreBaseURL}/icons/${
    onHomeScreenBanner ? 'profile-icon.svg' : 'profile-scroll.svg'
  }`;
  useEffect(() => {
    setAgentInfo(getAgentInfo());
  }, []);

  return (
    <div
      {...{
        className:
          'relative flex items-center justify-center cursor-pointer text-nero rounded md:rounded-6.5 px-2',
        onClick: () => setShowAccountOptions((prevState) => !prevState),
        title
      }}
    >
      <div
        {...{
          className: `flex items-center justify-center ${
            onHomeScreenBanner ? 'text-white' : 'text-brand'
          }`
        }}
      >
        <Image
          {...{
            alt: 'Login',
            className: 'cursor-pointer header-icon-size',
            height: '33',
            layout: 'fixed',
            objectFit: 'contain',
            src: icon,
            width: '33'
          }}
        />
        <div className='mt-1 leading-4'>
          <Text
            {...{
              className: `${
                onHomeScreenBanner ? 'text-white' : 'text-brand'
              } ms-2 min-w-14 text-sm font-medium`,
              content: 'Hello,',
              HtmlTag: 'span'
            }}
          />
          <br />
          <UserName
            isScrolling={onHomeScreenBanner}
            name={context.authUser.name}
          />
        </div>
      </div>
      <div className='mt-4'>
        <Image
          alt='downIcon'
          className={`self-center mb-3.5 ${
            showAccountOptions ? 'transform rotate-180' : 'transform rotate-0'
          }`}
          height={15}
          layout='fixed'
          src={`${staticMediaStoreBaseURL}/icons/${
            onHomeScreenBanner ? 'down-arrow-white.svg' : 'down-orange.svg'
          }`}
          width={15}
        />
      </div>
      {showAccountOptions && (
        <DropdownMenu {...{ context, logOut, setShowAccountOptions }} />
      )}
    </div>
  );
};

export default AccountMenu;
