import { useRouter } from 'next/router';
import { Fragment } from 'react';

import { captureGTMEventNavBarClick } from '@/lib/gtm';
import { getPageURL, ROUTE_CONFIG } from '@/services/hostPortal.service';

const MegaMenuCategories = ({
  categories,
  handleNavOnMouseLeave,
  parentSlug
}) => {
  const router = useRouter();
  return (
    <Fragment>
      <div
        id='megamenu'
        className='z-101 fixed'
      ></div>
      <div className={'z-101 fixed'}>
        <nav className='hidden md:block ms-2.5 px-5 md:px-18 relative text-gray-900'>
          <div
            className='mx-auto flex relative'
            onMouseLeave={handleNavOnMouseLeave}
          >
            <div className='flex z-40'>
              <div className='static'>
                <div className='py-4 mega-menu bg-white shadow-megamenu max-w-271 w-full'>
                  <div className='container mx-auto w-full overflow-hidden'>
                    {categories.map((category, index) => (
                      <div
                        className='float-left px-4 w-full md:w-1/3 lg:w-1/4 xl:w-1/5 h-full z-101'
                        key={index}
                      >
                        <a
                          href={getPageURL({
                            pageName: ROUTE_CONFIG.L1_CATEGORY.label,
                            pathParams: {
                              parentSlug,
                              categorySlug: category.slug
                            }
                          })}
                          onClick={() => {
                            captureGTMEventNavBarClick({
                              category,
                              path: router.pathname
                            });
                          }}
                        >
                          <span className='cursor-pointer text-xs text-nero leading-8 font-medium py-3'>
                            {category.name}
                          </span>
                        </a>
                        <ul className=''>
                          {category.childCategories.map(
                            (childCategory, idx) => (
                              <a
                                key={idx}
                                href={getPageURL({
                                  pageName: ROUTE_CONFIG.L2_CATEGORY.label,
                                  pathParams: {
                                    parentSlug,
                                    categorySlug: category.slug,
                                    subCategorySlug: childCategory.slug
                                  }
                                })}
                                onClick={() => {
                                  captureGTMEventNavBarClick({
                                    category,
                                    childCategory: childCategory.name,
                                    path: router.pathname
                                  });
                                }}
                              >
                                <li className='pb-2 text-xs text-dim-gray leading-4 font-medium hover:text-gray-800'>
                                  {childCategory.name}
                                </li>
                              </a>
                            )
                          )}
                        </ul>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </Fragment>
  );
};

export default MegaMenuCategories;
