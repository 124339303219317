import Image from 'next/image';

import { Text } from '@/components/atomic/nuclei';
import { staticMediaStoreBaseURL } from '@/config/common';

const InvalidOtpModal = ({ setShowInvalidOtpModal }) => (
  <div
    aria-labelledby='modal-title'
    aria-modal='true'
    className='fixed z-50 inset-0 overflow-y-auto w-full animated fade-in'
    role='dialog'
  >
    <div className='flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0 w-full'>
      <div
        className='fixed inset-0 bg-nero bg-opacity-80 transition-opacity'
        aria-hidden='true'
      ></div>

      <span
        className='hidden sm:inline-block sm:align-middle sm:h-screen'
        aria-hidden='true'
      >
        &#8203;
      </span>

      <div
        className='inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform
        transition-all sm:my-8 sm:align-middle sm:max-w-xs sm:w-full'
      >
        <div
          className='z-50 text-black relative flex justify-end p-3 cursor-pointer'
          onClick={() => setShowInvalidOtpModal(false)}
        >
          <Image
            alt={`${staticMediaStoreBaseURL}/icons/close-gray.svg`}
            className='text-black'
            height={15}
            src={`${staticMediaStoreBaseURL}/icons/close-gray.svg`}
            width={15}
          ></Image>
        </div>
        <div className='bg-white px-4 pb-4 sm:p-6 sm:py-5 w-full'>
          <div className='mt-3 text-center sm:mt-0 w-3/4 mx-auto w-full'>
            <Text
              {...{
                className: 'text-sm leading-6 w-full font-medium text-gray-900',
                content: 'Oops!',
                HtmlTag: 'h3',
                id: 'modal-title'
              }}
            />
            <div className='mt-2'>
              <Text
                {...{
                  className: 'text-sm text-gray-500',
                  content: 'Invalid OTP Please try again.'
                }}
              />
            </div>
          </div>
        </div>
        <div className='py-3 px-4 w-3/4 mx-auto flex justify-center items-center'>
          <button
            className='bg-coral text-white rounded-lg p-2 font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 hover:animate-wiggle'
            onClick={() => setShowInvalidOtpModal(false)}
            type='button'
          >
            Try again
          </button>
        </div>
      </div>
    </div>
  </div>
);

export default InvalidOtpModal;
