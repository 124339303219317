import config from '@/config';
import { getPageURL, ROUTE_CONFIG } from '@/services/hostPortal.service';

export const ROLE = {
  HOST: 'HOST',
  PLANNER: 'PLANNER',
  HOST_GUEST: 'HOST_GUEST'
};

export const PRODUCT_TYPE = {
  CART: 'Cart',
  QUOTE: 'Quote'
};

export const PAYMENT_STATUS = {
  FAILED: 'FAILED',
  PAID: 'PAID',
  SUSPENDED: 'SUSPENDED',
  UNCONFIRMED: 'UNCONFIRMED',
  VOID: 'VOID'
};

export const ORDER_STATUS = {
  CANCELLED: {
    label: 'Cancelled',
    value: 'CANCELLED'
  },
  COMPLETED_NOT_SETTLED: {
    label: 'Completed Not Settled',
    value: 'COMPLETED_NOT_SETTLED'
  },
  COMPLETED_SETTLED: {
    label: 'Completed Settled',
    value: 'COMPLETED_SETTLED'
  },
  DISPUTED: {
    label: 'Disputed',
    value: 'DISPUTED'
  },
  ORDER_CONFIRMED: {
    label: 'Order Confirmed',
    value: 'ORDER_CONFIRMED'
  },
  ORDER_INITIATED: {
    label: 'Order Initiated',
    value: 'ORDER_INITIATED'
  },
  ORDER_PLACED: {
    label: 'Order Placed',
    value: 'ORDER_PLACED'
  },
  PARTNER_BOOKED: {
    label: 'Partner Booked',
    value: 'PARTNER_BOOKED'
  },
  POSTPONED: {
    label: 'Postponed',
    value: 'POSTPONED'
  },
  READY_FOR_EXECUTION: {
    label: 'Ready for Execution',
    value: 'READY_FOR_EXECUTION'
  },
  SETTLED_AND_CLOSED: {
    label: 'Settled and Closed',
    value: 'SETTLED_AND_CLOSED'
  },
  SUSPENDED: {
    label: 'Suspended',
    value: 'SUSPENDED'
  }
};

export const DEVICE_SURFACE = {
  MOBILE: 'mobile',
  WEB: 'web'
};

export const CART_STATUS = {
  DRAFT: 'Draft',
  DISCARD: 'Discard',
  COLLABORATE: 'Collaborate',
  QUOTE: 'Quote',
  REVISE: 'Revise',
  APPROVE: 'Approve',
  REJECT: 'Reject',
  ORDER: 'Order',
  ABANDON: 'Abandon'
};

export const CART_LIST_TYPE = {
  EVENT: 'EventCartList',
  ORDER: 'OrderedCartList',
  ARCHIVE: 'ArchivedCartList'
};

export const PRODUCT_STATUS = {
  DRAFT: 'Draft',
  PUBLISHED: 'Published',
  UNPUBLISHED: 'Unpublished'
};

export const CART_ITEM_STATUS = {
  ACCEPTED: 'ACCEPTED',
  DECLINED: 'DECLINED',
  ORDERED: 'ORDERED',
  REQUESTED: 'REQUESTED',
  REQUEST_PENDING: 'REQUEST_PENDING'
};

export const MEDIA_SOURCE = {
  CODE_REPO: 'CODE_REPO',
  AWS_S3: 'AWS_S3',
  IMAGE_KIT: 'IMAGE_KIT',
  LOCALHOST: 'LOCALHOST'
};

export const NAMED_QUERY_PARAMS = {
  ANYBODY_CAN_PAY_RAW: 'anybody-can-pay',
  ORDER_ID: 'order-id',
  PAYMENT_FLOW_SOURCE: 'payment-flow-source',
  PAYMENT_ID: 'payment-id',
  PAYMENT_RECEIPT_STATUS: 'payment-receipt-status',
  PAYMENT_SOURCE_TYPE: 'reference-type',
  REFERENCE_ID: 'reference-id',
  TRANSACTION_AMOUNT: 'transaction-amount',
  USER_ID: 'user-id'
};

export const INVOICE_QUOTE_PDF_TYPE = {
  INVOICE: 'INVOICE',
  QUOTE: 'QUOTE'
};

export const PDF_TITLE = {
  PROFORMA_INVOICE: 'PROFORMA INVOICE',
  QUOTATION: 'QUOTATION',
  TAX_INVOICE: 'TAX INVOICE'
};

export const STATIC_MEDIA_STORE_PATH_STUB = '/static_images/host';

export const SAMPLE_MEDIA_STORE_PATH_STUB =
  'static_images/host/storybook-sample-images';

export const mediaStoreBaseURL =
  process.env.STORYBOOK_PUBLIC_MEDIA_STORE_URL ||
  process.env.NEXT_PUBLIC_MEDIA_STORE_URL ||
  '';

export const staticMediaStoreBaseURL = `${mediaStoreBaseURL}${STATIC_MEDIA_STORE_PATH_STUB}`;

export const IMAGEKIT_DAM_ROOT_DIRECTORY = `/API/${process.env.NEXT_PUBLIC_APP_ENV}`;

export const haflaBankDetails = [
  { label: 'Account No', value: '019100732211' },
  { label: 'Bank', value: 'Mashreq Bank' },
  { label: 'IBAN', value: 'AE460330000019100732211' },
  { label: 'Name', value: 'Evinops Limited' },
  { label: 'Swift Code', value: 'BOMLAEAD' }
];

export const haflaRegistrationDetails = {
  companyName: 'Evinops Limited (Hafla)',
  formattedAddress: 'Abu Dhabi, UAE PO Box: 46617',
  trnNumber: '100475504500003'
};

export const CART_ORDER_SOURCE = {
  ORDER: 'ORDER',
  CART: 'CART'
};

export const UNAVAILABLE_CART_ITEM_ALERT =
  'We apologize! One or more products in your cart are currently unavailable. Please remove those products from the cart to checkout.';

export const UNAVAILABLE_CART_ITEM_ALERT_FOR_QUOTE =
  'We apologize! One or more products in your quote are currently unavailable. Please ask the agent to remove those products to proceed to Checkout.';

export const homePageBannerDescriptionIconValuePair = [
  {
    icon: 'ideas-white-icon.svg',
    title: 'Ideas',
    subTitle: 'Personalized event concepts tailored to your vision.'
  },
  {
    icon: 'sourcing-white-icon.svg',
    title: 'Sourcing',
    subTitle: 'Effortlessly book venues, rentals and more.'
  },
  {
    icon: 'event-management-white-icon.svg',
    title: 'Event Day Management',
    subTitle: 'White glove service for flawless execution.'
  }
];

export const SOURCE_ORIGIN = {
  BOOKING_PORTAL: 'BOOKING_PORTAL',
  CORPORATE_PORTAL: 'CORPORATE_PORTAL',
  HOST_PORTAL: 'HOST_PORTAL',
  WEDDINGS_PORTAL: 'WEDDINGS_PORTAL'
};

export const kidsBirthdayEventCategories = [
  {
    image: 'bouncy.jpg',
    link: '/categories/kids-birthdayssct1/bouncy-and-slidessct1',
    name: 'Bouncy & Slides'
  },
  {
    image: 'cake.jpg',
    link: '/categories/kids-birthdayssct1/cakessct1',
    name: 'Cakes'
  },
  {
    image: 'decor.jpg',
    link: '/categories/kids-birthdayssct1/birthday-decorsct1',
    name: 'Birthday Decors'
  },
  {
    image: 'food.jpg',
    link: '/categories/kids-birthdayssct1/food-and-beveragessct1',
    name: 'Food & Beverages'
  },
  {
    image: 'furniture.jpg',
    link: '/categories/kids-birthdayssct1/furniture-and-setupsct1',
    name: 'Kids Furniture'
  },
  {
    image: 'kids_entertainment.jpg',
    link: '/categories/kids-birthdayssct1/kids-entertainmentsct1',
    name: 'Kids Entertainment'
  },
  {
    image: 'packages.jpg',
    link: '/categories/kids-birthdayssct1/birthday-packagessct1',
    name: 'Packages'
  },
  {
    image: 'venues.jpg',
    link: '/categories/venues/kids-party-venues',
    name: 'Birthday Venues'
  }
];

export const businessLocationInfo = [
  {
    iconUrl: '/partner-registration/Abu-Dhabi.jpeg',
    title: 'Abu Dhabi'
  },
  {
    iconUrl: '/partner-registration/Ajman.jpeg',
    title: 'Ajman'
  },
  {
    iconUrl: '/partner-registration/Dubai.jpeg',
    title: 'Dubai'
  },
  {
    iconUrl: '/partner-registration/Fujairah.jpeg',
    title: 'Fujairah'
  },
  {
    iconUrl: '/partner-registration/ras-al-khaimah.jpeg',
    title: 'Ras Al Khaimah'
  },
  {
    iconUrl: '/partner-registration/Sharjah.jpeg',
    title: 'Sharjah'
  },
  {
    iconUrl: '/partner-registration/Umm-Al-Qwain.jpeg',
    title: 'Umm Al Quwain'
  }
];
