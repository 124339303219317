import * as HttpService from '@/services/http.service';

import {
  CREATE_USER,
  GET_USER,
  GET_USER_COMPLETED_ORDERS,
  GET_USER_ONGOING_EVENTS,
  GET_USER_ONGOING_ORDERS,
  GET_USER_ORDER_DETAILS,
  GET_USER_PAST_EVENTS,
  POST_LEAD_MARKETING_REFERENCE,
  RECENT_OTP,
  UPDATE_USER_ADDRESS,
  UPDATE_USER_DETAILS,
  UPDATE_USER_TIMEZONE,
  USER_LOGIN
} from '@/services/url.service';

export const createUser = (userLoginDetails) =>
  HttpService.postWithoutAuth(CREATE_USER, userLoginDetails);

export const userLogin = (userLoginDetails) =>
  HttpService.postWithoutAuth(USER_LOGIN, userLoginDetails);

export const recentLoginOtp = (userLoginDetails) =>
  HttpService.postWithoutAuth(RECENT_OTP, userLoginDetails);

export const getUser = (userId) => HttpService.getWithoutAuth(GET_USER(userId));

export const getUserOngoingOrders = (userId, skip = 0, limit = 10) =>
  HttpService.getWithAuth(GET_USER_ONGOING_ORDERS(userId, skip, limit));

export const getUserCompletedOrders = (userId, skip = 0, limit = 10) =>
  HttpService.getWithAuth(GET_USER_COMPLETED_ORDERS(userId, skip, limit));

export const getUserOrderDetails = (userId, orderId) =>
  HttpService.getWithAuth(GET_USER_ORDER_DETAILS(userId, orderId));

export const updateUserDetails = (userId, userDetails) =>
  HttpService.postWithAuth(UPDATE_USER_DETAILS(userId), userDetails);

export const createLeadMarketingReferences = ({ leadMarketingReferences }) =>
  HttpService.postWithAuth(
    POST_LEAD_MARKETING_REFERENCE,
    leadMarketingReferences
  );

export const updateUserAddress = (userId, addressDetails) =>
  HttpService.postWithAuth(UPDATE_USER_ADDRESS(userId), addressDetails);

export const updateUserTimeZone = (userId, timeZone) =>
  HttpService.postWithoutAuth(UPDATE_USER_TIMEZONE(userId), timeZone);

export const getUserOngoingEvents = (userId, skip = 0, limit = 10) =>
  HttpService.getWithAuth(GET_USER_ONGOING_EVENTS(userId, skip, limit));

export const getUserPastEvents = (userId, skip = 0, limit = 10) =>
  HttpService.getWithAuth(GET_USER_PAST_EVENTS(userId, skip, limit));

export const getPurchaseEventInfoForGA = async ({
  userId,
  orderId,
  paymentFlowSource
}) => {
  const { status, entity } = await getUserOrderDetails(userId, orderId);
  if (status && entity?.orderItems) {
    return {
      orderItems: entity.orderItems,
      orderId,
      orderTotal: entity.orderTotal,
      paymentFlowSource
    };
  }
  return false;
};
